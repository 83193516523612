import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Modal } from 'carbon-components-react';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as addressesActions } from 'store/ducks/addresses';
import { Creators as authActions } from 'store/ducks/auth';

/* presentational components - imports */
import SignUp from 'components/presentational/SignUp';

/* utils - imports */
import INPUTS from 'utils/inputs';

class SignUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.authSignUp = this.authSignUp.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.state = {
      name: {
        value: '',
        message: '',
        validations: ['required'],
      },
      document: {
        value: '',
        message: '',
        validations: ['required', 'cpf'],
      },
      email: {
        value: '',
        message: '',
        validations: ['required', 'email'],
      },
      password: {
        value: '',
        message: '',
        validations: ['required', 'password'],
      },
      passwordConfirm: {
        value: '',
        message: '',
        validations: ['required', 'password'],
      },
      zipCode: {
        value: '',
        message: '',
        validations: ['required'],
      },
      street: {
        value: '',
        message: '',
        validations: ['required'],
      },
      number: {
        value: '',
        message: '',
        validations: ['required'],
      },
      complement: {
        value: '',
        message: '',
        validations: [],
      },
      neighborhood: {
        value: '',
        message: '',
        validations: ['required'],
      },
      city: {
        value: '',
        message: '',
        validations: ['required'],
      },
      uf: {
        value: '',
        message: '',
        validations: ['required'],
      },
      acceptTerms: {
        value: false,
        message: '',
        validations: ['requiredBoolean'],
      },
    };
  }

  async authSignUp(payload) {
    const { signUp, onModalSignUp } = this.props;
    await signUp({ ...payload, callback: onModalSignUp });
  }

  async onZipCode(zipCode) {
    const { getAddress, address } = this.props;
    const value = zipCode.replace(/[^0-9.]/g, '');

    if (
      value.length === 8 &&
      ((address !== null && address.zip_code !== value) || address === null)
    ) {
      await getAddress(value);
    }
  }

  onChange(name, value) {
    if (this.state[name].value !== value)
      this.setState(INPUTS.onChange(name, this.state[name], value));
    if (name === 'zipCode') this.onZipCode(value);
  }

  onValidate(name, value) {
    this.setState({ [name]: INPUTS.onValidate(this.state[name], value) });
  }

  onSubmit() {
    let {
      name,
      document,
      email,
      password,
      passwordConfirm,
      zipCode,
      street,
      number,
      complement,
      neighborhood,
      city,
      uf,
      acceptTerms,
    } = this.state;

    name = INPUTS.onValidate(name, name.value);
    document = INPUTS.onValidate(document, document.value);
    email = INPUTS.onValidate(email, email.value);
    password = INPUTS.onValidate(password, password.value);
    passwordConfirm = INPUTS.onValidate(passwordConfirm, passwordConfirm.value);
    zipCode = INPUTS.onValidate(zipCode, zipCode.value);
    street = INPUTS.onValidate(street, street.value);
    number = INPUTS.onValidate(number, number.value);
    complement = INPUTS.onValidate(complement, complement.value);
    neighborhood = INPUTS.onValidate(neighborhood, neighborhood.value);
    city = INPUTS.onValidate(city, city.value);
    uf = INPUTS.onValidate(uf, uf.value);
    acceptTerms = INPUTS.onValidate(acceptTerms, acceptTerms.value);

    if (name.message === '' && document.message === '')
      this.authSignUp({
        name: name.value,
        document: document.value,
        email: email.value,
        password: password.value,
        zip_code: zipCode.value,
        street: street.value,
        number: number.value,
        complement: complement.value,
        neighborhood: neighborhood.value,
        city: city.value,
        state: uf.value,
        acceptTerms: acceptTerms.value,
      });
    else
      this.setState({
        ...this.state,
        name,
        document,
        email,
        password,
        passwordConfirm,
        zipCode,
        street,
        number,
        complement,
        neighborhood,
        city,
        uf,
        acceptTerms,
      });
  }

  updateAddress() {
    const { street, neighborhood, city, uf } = this.state;
    const { address } = this.props;

    if (
      address !== null &&
      (address.street !== street.value ||
        address.city !== city.value ||
        address.state !== uf.value ||
        address.neighborhood !== neighborhood.value)
    ) {
      this.setState({
        ...INPUTS.onChange('street', street, address.street),
        ...INPUTS.onChange('city', city, address.city),
        ...INPUTS.onChange('uf', uf, address.state),
        ...INPUTS.onChange('neighborhood', neighborhood, address.neighborhood),
      });
    }
  }

  render() {
    const {
      isLoading,
      isLoadingAddress,
      success,
      message,
      modalSignUp,
      onModalSignUp,
      onModalSignIn,
    } = this.props;
    this.updateAddress();

    return (
      <Modal
        modalHeading="Entrar na plataforma"
        open={modalSignUp}
        primaryButtonText="Cadastrar"
        secondaryButtonText="Cancelar"
        onRequestClose={onModalSignUp}
        onRequestSubmit={this.onSubmit}
        onSecondarySubmit={onModalSignUp}
      >
        <SignUp
          key="signUpContainer"
          onChange={this.onChange}
          onValidate={this.onValidate}
          onSubmit={this.onSubmit}
          isLoading={isLoading}
          isLoadingAddress={isLoadingAddress}
          success={success}
          message={message}
          onModalSignIn={onModalSignIn}
          {...this.state}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  success: state.auth.success,
  message: state.auth.message,
  user: state.auth.user,
  address: state.addresses.address,
  isLoadingAddress: state.addresses.isLoading,
});

const mapDispatchToProps = dispatch => ({
  getAddress: payload => dispatch(addressesActions.address(payload)),
  signUp: payload => dispatch(authActions.signUp(payload)),
});

SignUpContainer.propTypes = {
  signUp: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  zipCode: PropTypes.func.isRequired,
  isLoadingAddress: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SignUpContainer.defaultProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpContainer);
