import React from 'react';

/* carbon - imports */
import { Dropdown } from 'carbon-components-react';

/* assets - imports */
import { user } from 'assets/icons';

import './UserStyle.scss';

const User = ({ auth, onModalSignIn, onSignOut }) => (
  <div className="user-container">
    <Dropdown
      id="id"
      type="inline"
      selectedItem={null}
      label={
        <span className="label-login">
          {user()}
          {auth !== null && auth.name.split(' ')[0]}
        </span>
      }
      items={
        auth !== null
          ? [{ id: 'sign-out', label: 'Sair' }]
          : [{ id: 'sign-in', label: 'Login' }]
      }
      onChange={event => {
        switch (event.selectedItem.id) {
          case 'sign-in':
            onModalSignIn();
            break;
          case 'sign-out':
            onSignOut();
            break;
          default:
            break;
        }
      }}
    />
  </div>
);

export default User;
