import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as authActions } from 'store/ducks/auth';
import { Creators as audiencesActions } from 'store/ducks/audiences';
import { Creators as ordersActions } from 'store/ducks/orders';

/* modal components - imports */
import SignInContainer from 'containers/SignInContainer';
import SignUpContainer from 'containers/SignUpContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';

/* presentational components - imports */
import AudienceRequests from 'components/presentational/AudienceRequests';

/* config - imports */
import { history } from 'config/routes';

class AudienceRequestsContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.onModalRequest = this.onModalRequest.bind(this);
    this.onModalSignIn = this.onModalSignIn.bind(this);
    this.onModalSignUp = this.onModalSignUp.bind(this);
    this.onModalForgotPassword = this.onModalForgotPassword.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
    this.state = {
      modalRequest: false,
      modalSignIn: false,
      modalSignUp: false,
      modalForgotPassword: false,
      onCallback: () => {},
    };
  }

  componentDidMount() {
    const {
      ordersList,
      ordersYesList,
      ordersNoList,
      match: {
        params: { audienceId },
      },
    } = this.props;

    const votingClosed = false;

    this.getAudience(audienceId);
    ordersList({ audienceId, votingClosed, favorable: 'other', page: 1 });
    ordersYesList({ audienceId, votingClosed, favorable: 'yes', page: 1 });
    ordersNoList({ audienceId, votingClosed, favorable: 'no', page: 1 });
  }

  async getAudience(id) {
    const { audience } = this.props;
    await audience({ id });
  }

  async onModalSignIn(onCallback = () => {}) {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignUp) await this.onModalSignUp();
    this.setState({
      modalSignIn: !modalSignIn,
      modalForgotPassword: false,
      onCallback,
    });
  }

  async onModalSignUp() {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignIn) await this.onModalSignIn();
    this.setState({ modalSignUp: !modalSignUp, modalForgotPassword: false });
  }

  async onModalForgotPassword(onCallback = () => {}) {
    const { modalForgotPassword } = this.state;
    this.setState({
      modalForgotPassword: !modalForgotPassword,
      modalSignIn: false,
      onCallback,
    });
  }

  async onModalRequest() {
    const { auth } = this.props;
    if (auth) {
      this.setState({ modalRequest: !this.state.modalRequest });
    } else {
      this.onModalSignIn(() =>
        this.setState({ modalRequest: !this.state.modalRequest }),
      );
    }
  }

  async onSignOut() {
    const { signOut } = this.props;
    await signOut();
  }

  render() {
    const {
      modalRequest,
      modalSignIn,
      modalSignUp,
      modalForgotPassword,
      onCallback,
    } = this.state;
    const {
      orders,
      ordersYes,
      ordersNo,
      detailAudience,
      isLoading,
      auth,
      match: {
        params: { audienceId, stageId },
      },
    } = this.props;

    return [
      <AudienceRequests
        key="audienceRequestsContainer"
        history={history}
        audienceId={audienceId}
        stageId={stageId}
        orders={orders}
        ordersYes={ordersYes}
        ordersNo={ordersNo}
        isLoading={isLoading}
        auth={auth}
        {...detailAudience}
        modalRequest={modalRequest}
        onModalRequest={this.onModalRequest}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onSignOut={this.onSignOut}
      />,
      <SignInContainer
        modalSignIn={modalSignIn}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
      <SignUpContainer
        modalSignUp={modalSignUp}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
      />,
      <ForgotPasswordContainer
        modalForgotPassword={modalForgotPassword}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  detailAudience: state.audience.audience,
  orders: state.orders.orders,
  ordersYes: state.orders.ordersYes,
  ordersNo: state.orders.ordersNo,
  isLoading:
    state.orders.isLoading ||
    state.orders.isLoadingNo ||
    state.orders.isLoadingYes ||
    state.audience.isLoading,
});

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(authActions.signOut()),
  audience: payload => dispatch(audiencesActions.audience(payload)),
  ordersList: payload => dispatch(ordersActions.ordersList(payload)),
  ordersYesList: payload => dispatch(ordersActions.ordersYesList(payload)),
  ordersNoList: payload => dispatch(ordersActions.ordersNoList(payload)),
});

AudienceRequestsContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  ordersList: PropTypes.func.isRequired,
  ordersYesList: PropTypes.func.isRequired,
  ordersNoList: PropTypes.func.isRequired,
  orders: PropTypes.array,
  isLoading: PropTypes.bool,
};

AudienceRequestsContainer.defaultProps = {
  orders: [],
  isLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceRequestsContainer);
