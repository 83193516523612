import React from 'react';
import PropTypes from 'prop-types';

/* styles - imports */
import './BoxButtonStyle.scss';

const BoxButton = ({ labelText, checked, onClick }) => (
  <div
    className={`box-button ${checked && 'box-button--checked'}`}
    role="presentation"
    onClick={() => onClick()}
  >
    <div className="bx--grid bb--container">
      <div className="bx--row">
        <div className="bx--col">
          <p className="bb--labelText">{labelText}</p>
        </div>
      </div>
    </div>
  </div>
);

BoxButton.propTypes = {
  labelText: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

BoxButton.defaultProps = {};

export default BoxButton;
