import CONSTANTS from './constants';

const ENDPOINTS = {
  SIGN_IN: `${CONSTANTS.URL_HOST}/auth/sign_in`,
  SIGN_UP: `${CONSTANTS.URL_HOST}/users`,
  FORGOT_PASSWORD: `${CONSTANTS.URL_HOST}/users/reset_password`,
  GET_AUDIENCES: `${CONSTANTS.URL_HOST}/audiences`,
  GET_AUDIENCE: id => `${CONSTANTS.URL_HOST}/audiences/${id}`,
  GET_ORDERS_FROM_AUDIENCE: id =>
    `${CONSTANTS.URL_HOST}/audiences/${id}/orders`,
  GET_ORDER: id => `${CONSTANTS.URL_HOST}/orders/${id}`,
  CREATE_ORDER: `${CONSTANTS.URL_HOST}/orders`,
  ORDER_LIKE: id => `${CONSTANTS.URL_HOST}/orders/${id}/like`,
  ORDER_COMMENT: `${CONSTANTS.URL_HOST}/comments`,
  CREATE_VOTE: `${CONSTANTS.URL_HOST}/votes`,
  GET_ADDRESS: zipCode => `${CONSTANTS.URL_HOST}/cep/find_cep?cep=${zipCode}`,
};

export default ENDPOINTS;
