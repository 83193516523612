import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Link } from 'carbon-components-react';

/* core components - imports */
import User from 'components/core/Layout/User';

/* config - imports */
import * as urls from 'config/urls';

/* assets - imports */
import { arrowLeft } from 'assets/icons';

/* styles - imports */
import './HeaderStyle.scss';

const HeaderSubtitle = subtitle =>
  subtitle !== '' ? (
    <div className="header-subtitle">
      <h3>{subtitle}</h3>
    </div>
  ) : null;

const Header = ({ auth, title, subtitle, url, onSignOut, onModalSignIn }) => (
  <div className="header-container" key="headerComponentContainer">
    <div className="header-body">
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col-sm-1 bx--col-lg-15">
            <Link className="header-back" href={url}>
              {arrowLeft()}
            </Link>
          </div>
          <div className="bx--col-sm-3 bx--col-lg-1">
            <User
              auth={auth}
              onSignOut={onSignOut}
              onModalSignIn={onModalSignIn}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col-sm-3 bx--col-lg-13">
            <h2 className="header-title">{title}</h2>
          </div>
        </div>
      </div>
    </div>
    <div className="bx--grid">
      <div className="bx--row">
        <div className="bx--col">{HeaderSubtitle(subtitle)}</div>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  url: PropTypes.string,
  auth: PropTypes.shape({
    name: PropTypes.string,
  }),
};

Header.defaultProps = {
  subtitle: '',
  url: urls.DASHBOARD,
  auth: null,
};

export default Header;
