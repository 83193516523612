import { takeLatest, put } from 'redux-saga/effects';
import ENDPOINTS from 'config/endpoints';
import axios from 'axios';

import { Types } from '../ducks/addresses';

function getAddress(zipCode) {
  return axios
    .get(`${ENDPOINTS.GET_ADDRESS(zipCode)}`)
    .then(res => res.data)
    .catch(error => {
      const response = error.response.status === 404 ? error.response : error;
      throw response;
    });
}

function* address({ payload }) {
  try {
    const response = yield getAddress(payload);
    yield put({ type: Types.ADDRESS_SUCCESS, address: response });
  } catch (e) {
    yield put({
      type: Types.ADDRESS_FAIL,
      error: 'Endereço não encontrado!',
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.ADDRESS, address);
}
