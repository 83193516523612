import React from 'react';

/* carbon - imports */
import {
  Accordion,
  AccordionItem,
  Button,
  Form,
  FormGroup,
  TextInput,
  Link,
} from 'carbon-components-react';

/* core components - imports */
import HeaderInline from 'components/core/Layout/HeaderInline';
import AudienceRecord from 'components/core/AudienceRecord';
import Comment from 'components/core/Comment';
import Loading from 'components/core/Loading';

/* config - imports */
import CONSTANTS from 'config/constants';
import * as urls from 'config/urls';

/* utils - imports */
import GET_ICON from 'utils/getIcon';

/* assets - imports */
import { likeRequest, arrowRight } from 'assets/icons';

/* styles - imports */
import './DetailRequestStyle.scss';

const renderEvidences = evidences =>
  evidences.map(evidence => (
    <AudienceRecord
      key={evidence.id}
      icon={GET_ICON(evidence.evidence_type)}
      title={evidence.name}
      url={
        !evidence.stealthy &&
        (evidence.evidence_type !== 'video'
          ? `${CONSTANTS.IMAGE_URL}${evidence.filename.url}`
          : evidence.url_video)
      }
      type="request"
      stealthy={evidence.stealthy}
      primary={!evidence.stealthy}
    />
  ));

const renderRequestEvidences = requestEvidences =>
  requestEvidences.map(requestEvidence => (
    <AudienceRecord
      key={requestEvidence.id}
      icon={GET_ICON('pdf')}
      title={requestEvidence.name}
      type="request"
      primary
    />
  ));

const renderComments = comments =>
  comments.map(comment => (
    <Comment name={comment.name_user} description={comment.message} />
  ));

const renderFormComment = (
  auth,
  comment,
  onChange,
  onComment,
  id,
  onModalSignIn,
  onModalSignUp,
) =>
  auth ? (
    <Form className="dr--input">
      <div className="bx--row">
        <div className="bx--col-sm-4">
          <FormGroup legendText="">
            <TextInput
              id="comment"
              placeholder="Escreva seu comentário"
              value={comment.value}
              maxLength={255}
              invalidText={comment.message}
              invalid={comment.message !== ''}
              onChange={event => onChange('comment', event.target.value)}
            />
          </FormGroup>
        </div>

        <div className="bx--col-sm-4">
          <Button
            href="#"
            className="bx--btn--sm"
            onClick={() => onComment(id)}
          >
            Enviar
            {arrowRight()}
          </Button>
        </div>
      </div>
    </Form>
  ) : (
    <p className="dr--input">
      <Link href="#signIn" onClick={() => onModalSignIn()}>
        Faça login
      </Link>
      {' ou '}
      <Link href="#signIn" onClick={() => onModalSignUp()}>
        cadastre-se
      </Link>
      {' para poder comentar'}
    </p>
  );

const Request = ({
  auth,
  id,
  author,
  description,
  fundamentals,
  filenameName,
  filenameType,
  filename,
  urlVideo,
  likes,
  evidences,
  requestEvidences,
  comments,
  amountComments,
  amountEvidences,
  amountRequestEvidences,
  comment,
  onLike,
  onChange,
  onComment,
  onModalSignIn,
  onModalSignUp,
  isStageOpenForOrders,
}) => (
  <div className="bx--grid">
    <div className="bx--row dr--info-container">
      <div className="bx--col-sm-3 bx--offset-lg-3 bx--col-lg-9">
        <div className="dr--info-text">
          <p className="dr--info-text-desc">Pedido realizado por</p>
          <p className="dr--info-text-name">{author}</p>
        </div>
      </div>
      <div className="bx--col-sm-1 bx--col-lg-1 dr--info-like">
        <Link
          style={{ color: '#0062ff' }}
          href={isStageOpenForOrders && '#like'}
          onClick={() => isStageOpenForOrders && onLike(id)}
        >
          <p className="dr--info-like">
            {likes}
            {likeRequest()}
          </p>
        </Link>
      </div>
    </div>
    <div className="bx--row">
      <div className="bx--col-sm-4 bx--offset-lg-3 bx--col-lg-10">
        <Accordion>
          <AccordionItem title="Pedido" open>
            <p>{description}</p>
          </AccordionItem>
          <AccordionItem title="Fundamento" open>
            <p>{fundamentals}</p>
          </AccordionItem>

          {filename.url !== null && (
            <AccordionItem title="Anexo" open>
              <AudienceRecord
                icon={GET_ICON(filenameType)}
                title={filenameName}
                url={
                  filenameType !== 'video'
                    ? `${CONSTANTS.IMAGE_URL}${filename.url}`
                    : urlVideo
                }
                type="request"
                primary
              />
            </AccordionItem>
          )}

          <AccordionItem
            title={`Provas (${amountEvidences + amountRequestEvidences})`}
            open
          >
            {renderEvidences(evidences)}
            {renderRequestEvidences(requestEvidences)}
          </AccordionItem>
          <AccordionItem title={`Comentários (${amountComments})`} open>
            {isStageOpenForOrders &&
              renderFormComment(
                auth,
                comment,
                onChange,
                onComment,
                id,
                onModalSignIn,
                onModalSignUp,
              )}
            {renderComments(comments)}
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  </div>
);

const DetailRequest = ({
  isLoading,
  auth,
  onChange,
  onSignOut,
  onLike,
  onComment,
  onModalSignIn,
  onModalSignUp,
  detailRequest,
  comment,
  audienceId,
  stageId,
}) => [
  <HeaderInline
    title="Detalhe do pedido"
    url={urls.AUDIENCE_REQUESTS(audienceId, stageId)}
    onSignOut={onSignOut}
    auth={auth}
    onModalSignIn={onModalSignIn}
  />,
  <div className="detail-request">
    {isLoading || detailRequest === null ? (
      <Loading />
    ) : (
      <Request
        auth={auth}
        id={detailRequest.id}
        author={detailRequest.author}
        description={detailRequest.description}
        fundamentals={detailRequest.fundamentals}
        filenameName={detailRequest.filenameName}
        filenameType={detailRequest.filenameType}
        filename={detailRequest.filename}
        urlVideo={detailRequest.urlVideo}
        likes={detailRequest.likes}
        evidences={detailRequest.evidences}
        // eslint-disable-next-line camelcase
        requestEvidences={detailRequest.request_evidences}
        comments={detailRequest.comments}
        // eslint-disable-next-line camelcase
        amountComments={detailRequest.amount_comments}
        // eslint-disable-next-line camelcase
        amountEvidences={detailRequest.amount_evidences}
        // eslint-disable-next-line camelcase
        amountRequestEvidences={detailRequest.amount_request_evidences}
        isStageOpenForOrders={detailRequest.isStageOpenForOrders}
        comment={comment}
        onLike={onLike}
        onComment={onComment}
        onChange={onChange}
        onSignOut={onSignOut}
        onModalSignIn={onModalSignIn}
        onModalSignUp={onModalSignUp}
      />
    )}
  </div>,
];

export default DetailRequest;
