import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import {
  Form,
  FormGroup,
  TextInput,
  Link,
  InlineNotification,
} from 'carbon-components-react';

/* styles - imports */
import './ForgotPasswordStyle.scss';

const ForgotPassword = ({
  success,
  message,
  onChange,
  onValidate,
  email,
  onModalSignUp,
}) => {
  return (
    <div className="nr forgot-password">
      <div className="fp">
        <Form invalid invalidText={email.message}>
          <FormGroup legendText="E-mail">
            <TextInput
              maxLength={255}
              value={email.value}
              labelText=""
              invalidText={email.message}
              invalid={email.message !== ''}
              onKeyUp={event => onValidate('email', event.target.value)}
              onChange={event => onChange('email', event.target.value)}
            />
          </FormGroup>
          <div className="bx--row">
            <div className="bx--col-sm-4">
              {!success && message.length !== 0 && (
                <InlineNotification
                  title="Ops, ocorreu algum erro!"
                  subtitle={message.join('\n')}
                  kind="error"
                />
              )}
            </div>
            <div className="bx--col-sm-4">
              <p className="si--sign-up">
                Não tem uma conta?{' '}
                <Link href="#" onClick={onModalSignUp}>
                  Cadastre-se aqui
                </Link>
              </p>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  authForgotPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ForgotPassword;
