import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  address: ['payload'],
  addressSuccess: ['payload', 'isLoading'],
  addressFail: ['isLoading', 'error'],
});

const INITIAL_STATE = {
  address: null,
  isLoading: false,
};

const requestAddress = state => ({
  ...state,
  isLoading: true,
});
const requestAddressSuccess = (state, payload) => ({
  ...state,
  address: payload.address,
  isLoading: false,
});
const requestAddressFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

export default createReducer(INITIAL_STATE, {
  [Types.ADDRESS]: requestAddress,
  [Types.ADDRESS_SUCCESS]: requestAddressSuccess,
  [Types.ADDRESS_FAIL]: requestAddressFail,
});
