import React from 'react';
// import PropTypes from 'prop-types';

/* core components - imports */
import Navbar from 'components/core/Layout/Navbar';
import Audience from 'components/core/Audience';
import Loading from 'components/core/Loading';

/* styles - imports */
import './DashboardStyle.scss';

const renderAudiences = audiences => {
  return audiences.map((audience, index) => (
    <Audience key={`audienceComponent${index}`} {...audience} />
  ));
};

const Dashboard = ({ audiences, isLoading }) => {
  return [
    <Navbar key="dashboardNavbar" />,
    <div className="dashboard-body">
      {isLoading && <Loading />}
      <div className="bx--grid">
        <div className="bx--row">{renderAudiences(audiences)}</div>
      </div>
    </div>,
  ];
};

export default Dashboard;
