import React from 'react';

/* styles - imports */
import './LoadingStyle.scss';

const Loading = () => (
  <div className="loading-container">
    <div className="lc lc-one" />
    <div className="lc lc-two" />
    <div className="lc lc-three" />
  </div>
);

export default Loading;
