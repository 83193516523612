import { takeLatest, put } from 'redux-saga/effects';
import ENDPOINTS from 'config/endpoints';
import axios from 'axios';

import { Types } from '../ducks/auth';

function login(params) {
  return axios
    .post(ENDPOINTS.SIGN_IN, params)
    .then(res => res)
    .catch(e => {
      const response =
        e.response.status === 404 ? e.response : e.response.data.errors;
      throw response;
    });
}

function register(params) {
  return axios
    .post(ENDPOINTS.SIGN_UP, params)
    .then(res => res)
    .catch(e => {
      const response =
        e.response.status === 404 ? e.response : e.response.data.errors;
      throw response;
    });
}

function password(params) {
  return axios
    .post(ENDPOINTS.FORGOT_PASSWORD, params)
    .then(res => res)
    .catch(() => {
      const response = ['Ocorreu algum error, tente novamente!'];
      throw response;
    });
}

function* signIn({ payload }) {
  try {
    const response = yield login({
      email: payload.email,
      password: payload.password,
    });

    localStorage.setItem('accessToken', response.headers['access-token']);
    localStorage.setItem('client', response.headers.client);
    localStorage.setItem('uid', response.headers.uid);
    localStorage.setItem('user', JSON.stringify(response.data.data));

    payload.callback();

    yield put({
      type: Types.SIGN_IN_SUCCESS,
      user: response.data.data,
    });
  } catch (messages) {
    yield put({
      type: Types.SIGN_IN_FAIL,
      message: messages,
    });
  }
}

function* signUp({ payload }) {
  try {
    const response = yield register(payload);

    payload.callback();

    yield put({
      type: Types.SIGN_UP_SUCCESS,
      user: response.data.data,
    });

    yield put({
      type: Types.SIGN_IN,
      payload: {
        email: payload.email,
        password: payload.password,
        callback: () => {},
      },
    });
  } catch (messages) {
    yield put({
      type: Types.SIGN_UP_FAIL,
      message: messages,
    });
  }
}

function* signOut() {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
    localStorage.removeItem('user');
    yield put({ type: Types.SIGN_OUT_SUCCESS });
  } catch (messages) {
    yield put({
      type: Types.SIGN_OUT_FAIL,
      message: 'Falha ao fazer logout',
    });
  }
}

function* forgotPassword({ payload }) {
  try {
    console.log(payload);
    yield password({ email: payload.email });
    payload.callback();

    yield put({ type: Types.FORGOT_PASSWORD_SUCCESS });
  } catch (messages) {
    yield put({
      type: Types.FORGOT_PASSWORD_FAIL,
      message: messages,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.SIGN_IN, signIn);
  yield takeLatest(Types.SIGN_UP, signUp);
  yield takeLatest(Types.SIGN_OUT, signOut);
  yield takeLatest(Types.FORGOT_PASSWORD, forgotPassword);
}
