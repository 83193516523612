import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  createOrder: ['payload', 'finish'],
  createOrderSuccess: ['order'],
  createOrderFail: ['message'],

  ordersList: ['payload'],
  ordersListSuccess: ['payload'],
  ordersListFail: ['error'],

  ordersYesList: ['payload'],
  ordersYesListSuccess: ['payload'],
  ordersYesListFail: ['error'],

  ordersNoList: ['payload'],
  ordersNoListSuccess: ['payload'],
  ordersNoListFail: ['error'],

  order: ['payload'],
  orderSuccess: ['payload'],
  orderFail: ['error'],

  orderLike: ['payload'],
  orderLikeSuccess: ['payload'],
  orderLikeFail: ['error'],

  orderComment: ['payload'],
  orderCommentSuccess: ['payload'],
  orderCommentFail: ['error'],
});

const INITIAL_STATE = {
  order: null,
  orders: [],
  totalVotes: 0,
  ordersYes: [],
  totalVotesYes: 0,
  ordersNo: [],
  totalVotesNo: 0,
  isLoading: false,
  isLoadingYes: false,
  isLoadingNo: false,
  success: false,
  message: [],
};

const requestCreateOrder = () => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const requestCreateOrderSuccess = (state, action) => ({
  ...INITIAL_STATE,
  success: true,
  order: action.order,
});
const requestCreateOrderFail = (state, action) => ({
  ...INITIAL_STATE,
  message: action.message,
});

const requestOrdersList = state => ({ ...state, isLoading: true });
const requestOrdersListSuccess = (state, payload) => ({
  ...state,
  orders: payload.orders,
  totalVotes: payload.totalVotes,
  isLoading: false,
});
const requestOrdersListFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const requestOrdersListYes = state => ({ ...state, isLoadingYes: true });
const requestOrdersYesListSuccess = (state, payload) => ({
  ...state,
  ordersYes: payload.orders,
  totalVotesYes: payload.totalVotes,
  isLoadingYes: false,
});
const requestOrdersYesListFail = (state, error) => ({
  ...state,
  isLoadingYes: false,
  error,
});

const requestOrdersNoList = state => ({ ...state, isLoadingYes: true });
const requestOrdersNoListSuccess = (state, payload) => ({
  ...state,
  ordersNo: payload.orders,
  totalVotesNo: payload.totalVotes,
  isLoadingNo: false,
});
const requestOrdersNoListFail = (state, error) => ({
  ...state,
  isLoadingNo: false,
  error,
});

const requestOrder = state => ({ ...state, isLoading: true });
const requestOrderSuccess = (state, payload) => ({
  ...state,
  order: payload.order,
  isLoading: false,
});
const requestOrderFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const requestOrderLike = state => state;
const requestOrderLikeSuccess = state => ({
  ...state,
  order: {
    ...state.order,
    likes: state.order.likes + 1,
  },
  isLoading: false,
});
const requestOrderLikeFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const requestOrderComment = state => state;
const requestOrderCommentSuccess = (state, action) => ({
  ...state,
  order: {
    ...state.order,
    ...action.order,
  },
  isLoading: false,
});
const requestOrderCommentFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

export default createReducer(INITIAL_STATE, {
  [Types.CREATE_ORDER]: requestCreateOrder,
  [Types.CREATE_ORDER_SUCCESS]: requestCreateOrderSuccess,
  [Types.CREATE_ORDER_FAIL]: requestCreateOrderFail,

  [Types.ORDERS_LIST]: requestOrdersList,
  [Types.ORDERS_LIST_SUCCESS]: requestOrdersListSuccess,
  [Types.ORDERS_LIST_FAIL]: requestOrdersListFail,

  [Types.ORDERS_YES_LIST]: requestOrdersListYes,
  [Types.ORDERS_YES_LIST_SUCCESS]: requestOrdersYesListSuccess,
  [Types.ORDERS_YES_LIST_FAIL]: requestOrdersYesListFail,

  [Types.ORDERS_NO_LIST]: requestOrdersNoList,
  [Types.ORDERS_NO_LIST_SUCCESS]: requestOrdersNoListSuccess,
  [Types.ORDERS_NO_LIST_FAIL]: requestOrdersNoListFail,

  [Types.ORDER]: requestOrder,
  [Types.ORDER_SUCCESS]: requestOrderSuccess,
  [Types.ORDER_FAIL]: requestOrderFail,

  [Types.ORDER_LIKE]: requestOrderLike,
  [Types.ORDER_LIKE_SUCCESS]: requestOrderLikeSuccess,
  [Types.ORDER_LIKE_FAIL]: requestOrderLikeFail,

  [Types.ORDER_COMMENT]: requestOrderComment,
  [Types.ORDER_COMMENT_SUCCESS]: requestOrderCommentSuccess,
  [Types.ORDER_COMMENT_FAIL]: requestOrderCommentFail,
});
