import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Form, FormGroup } from 'carbon-components-react';

/* core components - imports */
import BoxTypeEvidence from 'components/core/BoxTypeEvidence';

/* styles - imports */
import '../NewRequestStyle.scss';

const RequestProofs = ({ setForm }) => (
  <div className="bx--grid">
    <div className="bx--row nr request-proofs">
      <div className="bx--col-sm-4 bx--offset-md-2 bx--col-md-4 bx--offset-lg-4 bx--col-lg-8">
        <Form>
          <p className="title">
            A parte que alega fato e faz pedido deve comprovar suas alegações
            com as provas que possue e seja capaz de produzir por si próprio.
            Somente deverão ser requeridas provas de terceiros que a parte não
            tem aptidão de produzir.
          </p>
          <FormGroup>
            <BoxTypeEvidence
              key="requestProofsTestimonial"
              title="Testemunhal"
              description="Texto de suporte explicando este tipo de prova"
              onClick={() => setForm(4)}
            />
            <BoxTypeEvidence
              key="requestProofsExpert"
              title="Pericial"
              description="Texto de suporte explicando este tipo de prova"
              onClick={() => setForm(5)}
            />
            <BoxTypeEvidence
              key="requestProofsDocumentary"
              title="Documental"
              description="Texto de suporte explicando este tipo de prova"
              onClick={() => setForm(6)}
            />
          </FormGroup>
        </Form>
      </div>
    </div>
  </div>
);

RequestProofs.propTypes = {
  setForm: PropTypes.func.isRequired,
};

export default RequestProofs;
