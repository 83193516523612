import React from 'react';
// import PropTypes from 'prop-types';

/* carbon - imports */
import { Modal } from 'carbon-components-react';

/* core components - imports */
import Header from 'components/core/Layout/Header';
import RequestsProcessed from 'components/core/RequestsProcessed';
import Loading from 'components/core/Loading';

/* config - imports */
import * as urls from 'config/urls';

/* styles - imports */
import './AudiencePostulatoryStyle.scss';

const Content = ({
  orders,
  ordersYes,
  ordersNo,
  audienceId,
  onModalRequest,
  questionRequest,
}) => (
  <div className="audience-requests">
    <div className="bx--grid">
      <div className="bx--row">
        <div className="bx--col">
          <h3 className="question">{questionRequest}</h3>
        </div>
      </div>
    </div>
    <RequestsProcessed
      onModalRequest={onModalRequest}
      orders={orders}
      ordersYes={ordersYes}
      ordersNo={ordersNo}
      audienceId={audienceId}
      hiddenRequests
    />
  </div>
);

const AudiencePostulatory = ({
  owner,
  title,
  history,
  orders,
  ordersYes,
  ordersNo,
  isLoading,
  audienceId,
  stageId,
  modalRequest,
  onModalRequest,
  onModalSignIn,
  onSignOut,
  auth,
  questionRequest,
}) => {
  return [
    <Header
      title={title}
      subtitle={owner}
      url={urls.AUDIENCE(audienceId)}
      onSignOut={onSignOut}
      auth={auth}
      onModalSignIn={onModalSignIn}
    />,
    isLoading ? (
      <Loading />
    ) : (
      <Content
        orders={orders}
        ordersYes={ordersYes}
        ordersNo={ordersNo}
        audienceId={audienceId}
        onModalRequest={onModalRequest}
        questionRequest={questionRequest}
      />
    ),
    <Modal
      modalHeading="Enviar Pedido"
      open={modalRequest}
      primaryButtonText="Continuar"
      secondaryButtonText="Cancelar"
      onRequestClose={onModalRequest}
      onRequestSubmit={() =>
        history.push(`/audiencias/${audienceId}/fases/${stageId}/novo-pedido`)
      }
      onSecondarySubmit={onModalRequest}
    >
      <p className="">
        Neste aviso deveremos informar sobre os dispositivos legais que envolvem
        o envio das informações bem como sugerir ao cidadão ler os outros
        pedidos e observar se não existe algum outro pedido existente que já
        contempla as suas reinvidicações.
      </p>
    </Modal>,
  ];
};

export default AudiencePostulatory;
