import { all } from 'redux-saga/effects';
import * as AuthSaga from './authSaga';
import * as AddressesSaga from './addressesSaga';
import * as AudiencesSaga from './audiencesSaga';
import * as OrdersSaga from './ordersSaga';
import * as VotesSaga from './votesSaga';

function* Sagas() {
  yield all([
    AuthSaga.watcherSaga(),
    AddressesSaga.watcherSaga(),
    AudiencesSaga.watcherSaga(),
    OrdersSaga.watcherSaga(),
    VotesSaga.watcherSaga(),
  ]);
}

export default Sagas;
