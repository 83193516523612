import React from 'react';

export const calendar = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill="#565656"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 2.00004H12.3333C13.2538 2.00004 14 2.74623 14 3.66671V12.3383C14 13.2587 13.2538 14.0049 12.3333 14.0049H3.66667C2.74619 14.0049 2 13.2587 2 12.3383V3.66671C2 2.74623 2.74619 2.00004 3.66667 2.00004H4.66667V1.66671C4.66667 1.48261 4.81591 1.33337 5 1.33337C5.18409 1.33337 5.33333 1.48261 5.33333 1.66671V2.00004H10.6667V1.66671C10.6667 1.48261 10.8159 1.33337 11 1.33337C11.1841 1.33337 11.3333 1.48261 11.3333 1.66671V2.00004ZM10.6667 2.66671H5.33333V3.00004C5.33333 3.18414 5.18409 3.33337 5 3.33337C4.81591 3.33337 4.66667 3.18414 4.66667 3.00004V2.66671H3.66667C3.11438 2.66671 2.66667 3.11442 2.66667 3.66671V5.33337H13.3333V3.66671C13.3333 3.11442 12.8856 2.66671 12.3333 2.66671H11.3333V3.00004C11.3333 3.18414 11.1841 3.33337 11 3.33337C10.8159 3.33337 10.6667 3.18414 10.6667 3.00004V2.66671ZM2.66667 6.00004H13.3333V12.3383C13.3333 12.8906 12.8856 13.3383 12.3333 13.3383H3.66667C3.11438 13.3383 2.66667 12.8906 2.66667 12.3383V6.00004Z"
    />
  </svg>
);

export const clock = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667ZM8.00016 14C11.3139 14 14.0002 11.3137 14.0002 8.00004C14.0002 4.68633 11.3139 2.00004 8.00016 2.00004C4.68645 2.00004 2.00016 4.68633 2.00016 8.00004C2.00016 11.3137 4.68645 14 8.00016 14ZM10.6047 9.86045C10.7117 9.71065 10.677 9.50247 10.5272 9.39546L8.29051 7.7978L7.32175 4.24567C7.27331 4.06806 7.09006 3.96335 6.91246 4.01179C6.73485 4.06022 6.63014 4.24347 6.67857 4.42108L7.67857 8.08775C7.69878 8.16182 7.74393 8.22666 7.80642 8.27128L10.1397 9.93795C10.2896 10.045 10.4977 10.0103 10.6047 9.86045Z"
      fill="#565656"
    />
  </svg>
);

export const pin = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6665 5.66667C4.6665 7.57935 6.131 9.14998 7.99984 9.31839V13.6667C7.99984 13.8508 8.14908 14 8.33317 14C8.51727 14 8.6665 13.8508 8.6665 13.6667V9.31839C10.5353 9.14998 11.9998 7.57935 11.9998 5.66667C11.9998 3.64162 10.3582 2 8.33317 2C6.30813 2 4.6665 3.64162 4.6665 5.66667ZM11.3332 5.66667C11.3332 7.32352 9.99003 8.66667 8.33317 8.66667C6.67632 8.66667 5.33317 7.32352 5.33317 5.66667C5.33317 4.00981 6.67632 2.66667 8.33317 2.66667C9.99003 2.66667 11.3332 4.00981 11.3332 5.66667Z"
      fill="#565656"
    />
  </svg>
);

export const arrowLeft = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M11.6096 16.0001H24.6668C25.035 16.0001 25.3335 16.2986 25.3335 16.6667C25.3335 17.0349 25.035 17.3334 24.6668 17.3334H11.6096L15.8049 21.5287C16.0653 21.789 16.0653 22.2111 15.8049 22.4715C15.5446 22.7318 15.1224 22.7318 14.8621 22.4715L9.52876 17.1382C9.26841 16.8778 9.26841 16.4557 9.52876 16.1953L14.8621 10.862C15.1224 10.6017 15.5446 10.6017 15.8049 10.862C16.0653 11.1224 16.0653 11.5445 15.8049 11.8048L11.6096 16.0001Z"
      fill="white"
    />
  </svg>
);

export const like = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66651 6.92138L6.25448 5.74544C6.52544 5.20352 6.6665 4.60596 6.6665 4.00008V3.83341C6.6665 3.18908 7.18884 2.66675 7.83317 2.66675C8.4775 2.66675 9.01699 3.15498 9.0811 3.79611L9.3015 6.00008H11.5265C12.447 6.00008 13.1932 6.74627 13.1932 7.66675C13.1932 7.72213 13.1904 7.77748 13.1849 7.83259L12.7849 11.8326C12.6997 12.6846 11.9828 13.3334 11.1265 13.3334H6.99984C6.55146 13.3334 6.14444 13.1564 5.84488 12.8684C5.66756 13.1479 5.35536 13.3334 4.99984 13.3334H3.6665C3.11422 13.3334 2.6665 12.8857 2.6665 12.3334V7.66675C2.6665 7.11446 3.11422 6.66675 3.6665 6.66675H4.99984C5.25596 6.66675 5.48959 6.76303 5.66651 6.92138ZM3.33317 7.66675V12.3334C3.33317 12.5175 3.48241 12.6667 3.6665 12.6667H4.99984C5.18393 12.6667 5.33317 12.5175 5.33317 12.3334V7.66675C5.33317 7.48265 5.18393 7.33342 4.99984 7.33342H3.6665C3.48241 7.33342 3.33317 7.48265 3.33317 7.66675ZM5.99984 11.6667V7.74544L6.85077 6.04358C7.16801 5.40909 7.33317 4.70946 7.33317 4.00008V3.83341C7.33317 3.55727 7.55703 3.33341 7.83317 3.33341C8.13499 3.33341 8.38771 3.56212 8.41774 3.86245L8.66816 6.36658C8.6852 6.53698 8.82859 6.66675 8.99984 6.66675H11.5265C12.0788 6.66675 12.5265 7.11446 12.5265 7.66675C12.5265 7.69998 12.5249 7.73319 12.5216 7.76625L12.1216 11.7663C12.0704 12.2775 11.6403 12.6667 11.1265 12.6667H6.99984C6.44755 12.6667 5.99984 12.219 5.99984 11.6667Z"
      fill="#171717"
    />
  </svg>
);

export const comment = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.03029 13.5281C1.91926 13.7711 2.12479 14.0394 2.38826 13.9955L5.53382 13.4712C6.30204 13.818 7.13848 14 8.00013 14C11.3138 14 14.0001 11.3137 14.0001 8C14.0001 4.68629 11.3138 2 8.00013 2C4.68642 2 2.00013 4.68629 2.00013 8C2.00013 9.21266 2.36108 10.3717 3.02408 11.3535L2.03029 13.5281ZM5.72287 12.8242C5.66143 12.7951 5.59259 12.7855 5.52556 12.7967L2.89733 13.2348L3.70963 11.4573C3.75952 11.3481 3.74687 11.2205 3.67649 11.1233C3.02365 10.2212 2.66679 9.13794 2.66679 8C2.66679 5.05448 5.05461 2.66667 8.00013 2.66667C10.9456 2.66667 13.3335 5.05448 13.3335 8C13.3335 10.9455 10.9456 13.3333 8.00013 13.3333C7.20155 13.3333 6.42853 13.1579 5.72287 12.8242Z"
      fill="#171717"
    />
  </svg>
);

export const pdf = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 19.5923V6.62029C20.0084 6.48754 19.9642 6.34936 19.8536 6.23876L15.8536 2.23876C15.7429 2.12815 15.6048 2.08389 15.472 2.09231H6.5C5.11929 2.09231 4 3.2116 4 4.59231V19.5923C4 20.973 5.11929 22.0923 6.5 22.0923H17.5C18.8807 22.0923 20 20.973 20 19.5923ZM6.5 3.09231H15V6.59231C15 6.86845 15.2239 7.09231 15.5 7.09231H19V19.5923C19 20.4207 18.3284 21.0923 17.5 21.0923H6.5C5.67157 21.0923 5 20.4207 5 19.5923V4.59231C5 3.76388 5.67157 3.09231 6.5 3.09231ZM16 3.79942V6.09231H18.2929L16 3.79942ZM13.8173 15.0509L12.288 11.9922C12.6999 11.8685 13 11.4864 13 11.0343V10.0343C13 9.48198 12.5523 9.03426 12 9.03426H11C10.4477 9.03426 10 9.48198 10 10.0343V11.0343C10 11.4864 10.3001 11.8685 10.712 11.9922L9.18266 15.0509C9.12344 15.04 9.06239 15.0343 9 15.0343H8C7.44772 15.0343 7 15.482 7 16.0343V17.0343C7 17.5865 7.44772 18.0343 8 18.0343H9C9.55228 18.0343 10 17.5865 10 17.0343V16.0343H13V17.0343C13 17.5865 13.4477 18.0343 14 18.0343H15C15.5523 18.0343 16 17.5865 16 17.0343V16.0343C16 15.482 15.5523 15.0343 15 15.0343H14C13.9376 15.0343 13.8766 15.04 13.8173 15.0509ZM11 10.0279V11.0279H12V10.0279H11ZM8 16.9899V15.9899H9V16.9899H8ZM14 15.9899V16.9899H15V15.9899H14ZM10.309 15.0113L11.5 12.6294L12.691 15.0113H10.309Z"
      fill="#0062FF"
    />
  </svg>
);

export const image = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 19.501V6.52898C20.0084 6.39623 19.9642 6.25806 19.8536 6.14745L15.8536 2.14745C15.7429 2.03684 15.6048 1.99258 15.472 2.001H6.5C5.11929 2.001 4 3.12029 4 4.501V19.501C4 20.8817 5.11929 22.001 6.5 22.001H17.5C18.8807 22.001 20 20.8817 20 19.501ZM6.5 3.001H15V6.501C15 6.77714 15.2239 7.001 15.5 7.001H19V19.501C19 20.3294 18.3284 21.001 17.5 21.001H6.5C5.67157 21.001 5 20.3294 5 19.501V4.501C5 3.67258 5.67157 3.001 6.5 3.001ZM16 3.70811V6.001H18.2929L16 3.70811ZM17 16.5121C17.0001 16.5047 17.0002 16.4973 17 16.4899V11.5C17 10.1193 15.8807 9 14.5 9H9.5C8.11929 9 7 10.1193 7 11.5V16.5C7 17.8807 8.11929 19 9.5 19H14.5C15.8767 19 16.9934 17.8873 17 16.5121ZM16 15.691V11.5C16 10.6716 15.3284 10 14.5 10H9.5C8.67157 10 8 10.6716 8 11.5V14.2929L9.14645 13.1464C9.34171 12.9512 9.65829 12.9512 9.85355 13.1464L12.5987 15.8916L14.2764 15.0528C14.4172 14.9824 14.5828 14.9824 14.7236 15.0528L16 15.691ZM14.5 16.059L15.9711 16.7946C15.8342 17.4819 15.2276 18 14.5 18H9.5C8.67157 18 8 17.3284 8 16.5V15.7071L9.5 14.2071L12.1464 16.8536C12.2986 17.0057 12.5311 17.0435 12.7236 16.9472L14.5 16.059ZM13 12V13H14V12H13ZM14 11H13C12.4477 11 12 11.4477 12 12V13C12 13.5523 12.4477 14 13 14H14C14.5523 14 15 13.5523 15 13V12C15 11.4477 14.5523 11 14 11Z"
      fill="#0062FF"
    />
  </svg>
);

export const video = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64837 16H5.5C4.11929 16 3 14.8807 3 13.5V8.5C3 7.11929 4.11929 6 5.5 6H12.5C13.8584 6 14.9637 7.08337 14.9991 8.43321L17.2276 6.57617C18.2034 5.76297 19.6537 5.89481 20.4669 6.87065C20.8114 7.284 21 7.80502 21 8.34307V13.6569C21 14.9272 19.9703 15.9569 18.7 15.9569C18.1619 15.9569 17.6409 15.7683 17.2276 15.4238L14.9991 13.5668C14.9637 14.9166 13.8584 16 12.5 16H10.3516L12.8254 18.1204C13.0351 18.3001 13.0593 18.6157 12.8796 18.8254C12.6999 19.0351 12.3843 19.0593 12.1746 18.8796L9 16.1585L5.8254 18.8796C5.61573 19.0593 5.30008 19.0351 5.12037 18.8254C4.94066 18.6157 4.96494 18.3001 5.1746 18.1204L7.64837 16ZM14 12.5033V12.4962V9.50382V9.49672V8.5C14 7.67157 13.3284 7 12.5 7H5.5C4.67157 7 4 7.67157 4 8.5V13.5C4 14.3284 4.67157 15 5.5 15H12.5C13.3284 15 14 14.3284 14 13.5V12.5033ZM15 12.2658V9.73419L17.8678 7.34439C18.4193 6.88475 19.2391 6.95928 19.6987 7.51084C19.8934 7.74446 20 8.03896 20 8.34307V13.6569C20 14.3749 19.418 14.9569 18.7 14.9569C18.3959 14.9569 18.1014 14.8503 17.8678 14.6556L15 12.2658Z"
      fill="#0062FF"
    />
  </svg>
);

export const chat = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6788 7.32147C19.809 8.26957 22.0001 11.1683 22.0001 14.5C22.0001 15.7913 21.6729 17.0357 21.0598 18.1387L21.9809 21.3626C22.0887 21.7398 21.7399 22.0885 21.3628 21.9808L18.1388 21.0596C17.0359 21.6728 15.7914 22 14.5001 22C11.1716 22 8.27129 19.8126 7.32158 16.6786C6.81451 16.5249 6.32543 16.3176 5.8614 16.0596L2.63748 16.9808C2.26031 17.0885 1.9116 16.7398 2.01936 16.3626L2.94048 13.1387C2.32734 12.0357 2.00012 10.7913 2.00012 9.5C2.00012 5.35786 5.35799 2 9.50012 2C12.8846 2 15.7455 4.24179 16.6788 7.32147ZM5.78994 15.04C5.92235 15.0022 6.0645 15.0207 6.18287 15.0911C7.17834 15.6831 8.31542 16 9.50012 16C13.09 16 16.0001 13.0899 16.0001 9.5C16.0001 5.91015 13.09 3 9.50012 3C5.91027 3 3.00012 5.91015 3.00012 9.5C3.00012 10.6847 3.31707 11.8218 3.90907 12.8173C3.97946 12.9356 3.99791 13.0778 3.96008 13.2102L3.22814 15.772L5.78994 15.04ZM17.0001 9.5C17.0001 9.15018 16.9762 8.80595 16.9298 8.46886C19.3501 9.44299 21.0001 11.8087 21.0001 14.5C21.0001 15.6847 20.6832 16.8218 20.0912 17.8173C20.0208 17.9356 20.0023 18.0778 20.0402 18.2102L20.7721 20.772L18.2103 20.04C18.0779 20.0022 17.9357 20.0207 17.8174 20.0911C16.8219 20.6831 15.6848 21 14.5001 21C11.8111 21 9.44431 19.3526 8.46903 16.9296C8.8081 16.9763 9.15241 17 9.50012 17C13.6423 17 17.0001 13.6421 17.0001 9.5Z"
      fill="#313344"
    />
  </svg>
);

export const likeRequest = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.79151 9.51687L8.59997 7.89995C8.97254 7.15481 9.1665 6.33317 9.1665 5.50008V5.27091C9.1665 4.38496 9.88471 3.66675 10.7707 3.66675C11.6566 3.66675 12.3984 4.33807 12.4866 5.21962L12.7896 8.25008H15.849C17.1147 8.25008 18.1407 9.27609 18.1407 10.5417C18.1407 10.6179 18.1369 10.694 18.1293 10.7698L17.5793 16.2698C17.4622 17.4413 16.4764 18.3334 15.299 18.3334H9.62484C9.00833 18.3334 8.44867 18.09 8.03677 17.694C7.79295 18.0783 7.36369 18.3334 6.87484 18.3334H5.0415C4.28211 18.3334 3.6665 17.7178 3.6665 16.9584V10.5417C3.6665 9.78236 4.28211 9.16675 5.0415 9.16675H6.87484C7.227 9.16675 7.54825 9.29914 7.79151 9.51687ZM4.58317 10.5417V16.9584C4.58317 17.2115 4.78837 17.4167 5.0415 17.4167H6.87484C7.12797 17.4167 7.33317 17.2115 7.33317 16.9584V10.5417C7.33317 10.2886 7.12797 10.0834 6.87484 10.0834H5.0415C4.78837 10.0834 4.58317 10.2886 4.58317 10.5417ZM8.24984 16.0417V10.6499L9.41986 8.30989C9.85607 7.43747 10.0832 6.47547 10.0832 5.50008V5.27091C10.0832 4.89122 10.391 4.58341 10.7707 4.58341C11.1857 4.58341 11.5332 4.89789 11.5745 5.31083L11.9188 8.75402C11.9422 8.98832 12.1394 9.16675 12.3748 9.16675H15.849C16.6084 9.16675 17.224 9.78236 17.224 10.5417C17.224 10.5874 17.2217 10.6331 17.2172 10.6786L16.6672 16.1786C16.5969 16.8815 16.0054 17.4167 15.299 17.4167H9.62484C8.86545 17.4167 8.24984 16.8011 8.24984 16.0417Z"
      fill="#0062FF"
    />
  </svg>
);

export const close = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 11.2929L16.1464 7.14645C16.3417 6.95118 16.6583 6.95118 16.8536 7.14645C17.0488 7.34171 17.0488 7.65829 16.8536 7.85355L12.7071 12L16.8536 16.1464C17.0488 16.3417 17.0488 16.6583 16.8536 16.8536C16.6583 17.0488 16.3417 17.0488 16.1464 16.8536L12 12.7071L7.85355 16.8536C7.65829 17.0488 7.34171 17.0488 7.14645 16.8536C6.95118 16.6583 6.95118 16.3417 7.14645 16.1464L11.2929 12L7.14645 7.85355C6.95118 7.65829 6.95118 7.34171 7.14645 7.14645C7.34171 6.95118 7.65829 6.95118 7.85355 7.14645L12 11.2929Z"
      fill="black"
    />
  </svg>
);

export const user = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99969 11.9997C1.99969 6.4767 6.4767 1.99969 11.9997 1.99969C17.5227 1.99969 21.9997 6.4767 21.9997 11.9997C21.9997 17.5227 17.5227 21.9997 11.9997 21.9997C6.4767 21.9997 1.99969 17.5227 1.99969 11.9997ZM15.0777 14.9407L18.5347 16.5117C18.8637 16.6617 19.1527 16.8787 19.3847 17.1457C20.4027 15.6867 20.9997 13.9127 20.9997 11.9997C20.9997 7.0297 16.9707 2.99969 11.9997 2.99969C7.0297 2.99969 2.99969 7.0297 2.99969 11.9997C2.99969 13.9127 3.59669 15.6867 4.61569 17.1457C4.84769 16.8787 5.13669 16.6617 5.46569 16.5117L8.92269 14.9407C7.75169 14.0257 6.99969 12.6007 6.99969 10.9997C6.99969 8.2387 9.2387 5.99969 11.9997 5.99969C14.7617 5.99969 16.9997 8.2387 16.9997 10.9997C16.9997 12.6007 16.2477 14.0257 15.0777 14.9407ZM11.9997 6.99969C9.79069 6.99969 7.99969 8.79069 7.99969 10.9997C7.99969 13.2087 9.79069 14.9997 11.9997 14.9997C14.2087 14.9997 15.9997 13.2087 15.9997 10.9997C15.9997 8.79069 14.2087 6.99969 11.9997 6.99969ZM11.9997 20.9997C9.3127 20.9997 6.90169 19.8227 5.25169 17.9557C5.40569 17.7247 5.6217 17.5387 5.87969 17.4227L9.96169 15.5667C10.5837 15.8447 11.2737 15.9997 11.9997 15.9997C12.7257 15.9997 13.4157 15.8447 14.0387 15.5667L18.1207 17.4227C18.3777 17.5387 18.5937 17.7247 18.7477 17.9557C17.0987 19.8227 14.6867 20.9997 11.9997 20.9997Z"
      fill="white"
    />
  </svg>
);

/* ilustrations */

export const checkMarkCircle = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.9999 58.6666C17.2723 58.6666 5.33325 46.7276 5.33325 32C5.33325 17.2724 17.2723 5.33331 31.9999 5.33331C46.7275 5.33331 58.6666 17.2724 58.6666 32C58.6666 46.7276 46.7275 58.6666 31.9999 58.6666ZM31.9999 56C45.2548 56 55.9999 45.2548 55.9999 32C55.9999 18.7451 45.2548 7.99998 31.9999 7.99998C18.7451 7.99998 7.99992 18.7451 7.99992 32C7.99992 45.2548 18.7451 56 31.9999 56ZM42.2761 24.3905C41.7554 23.8698 40.9111 23.8698 40.3904 24.3905L27.9999 36.781L23.6094 32.3905C23.0887 31.8698 22.2445 31.8698 21.7238 32.3905C21.2031 32.9112 21.2031 33.7554 21.7238 34.2761L27.0571 39.6095C27.5778 40.1302 28.422 40.1302 28.9427 39.6095L42.2761 26.2761C42.7968 25.7554 42.7968 24.9112 42.2761 24.3905Z"
      fill="#24A249"
    />
  </svg>
);

/* btn icons */

export const plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className="bx--btn__icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 7.5V3.5H7.5V7.5H3.5V8.5H7.5V12.5H8.5V8.5H12.5V7.5H8.5Z"
      fill="#8C8C8C"
    />
    <mask
      id="mask0"
      masktype="alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="10"
      height="10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 7.5V3.5H7.5V7.5H3.5V8.5H7.5V12.5H8.5V8.5H12.5V7.5H8.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)" />
  </svg>
);

export const arrowRight = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    className="bx--btn__icon"
  >
    <path
      d="M11.5284 9.51045H4.99984C4.81574 9.51045 4.6665 9.36216 4.6665 9.17923C4.6665 8.9963 4.81574 8.84801 4.99984 8.84801H11.5284L9.4308 6.76367C9.30063 6.63432 9.30063 6.4246 9.4308 6.29525C9.56098 6.1659 9.77203 6.1659 9.90221 6.29525L12.5689 8.94502C12.699 9.07437 12.699 9.28409 12.5689 9.41344L9.90221 12.0632C9.77203 12.1926 9.56098 12.1926 9.4308 12.0632C9.30063 11.9339 9.30063 11.7241 9.4308 11.5948L11.5284 9.51045Z"
      fill="white"
    />
  </svg>
);

export const checkMark = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className="bx--btn__icon"
  >
    <path
      d="M7.00008 9.86191L11.431 5.43094C11.5612 5.30077 11.7723 5.30077 11.9025 5.43094C12.0326 5.56112 12.0326 5.77217 11.9025 5.90235L7.23578 10.569C7.10561 10.6992 6.89455 10.6992 6.76438 10.569L4.76438 8.56902C4.6342 8.43884 4.6342 8.22779 4.76438 8.09761C4.89455 7.96744 5.10561 7.96744 5.23578 8.09761L7.00008 9.86191Z"
      fill="white"
    />
  </svg>
);
