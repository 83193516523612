import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Link, Button } from 'carbon-components-react';

/* core components - imports */
import Header from 'components/core/Layout/Header';
import AudienceRecord from 'components/core/AudienceRecord';
import AudienceInformation from 'components/core/AudienceInformation';
import Loading from 'components/core/Loading';

/* config - imports */
import CONSTANTS from 'config/constants';
import * as urls from 'config/urls';

/* utils - imports */
import GET_ICON from 'utils/getIcon';

/* assets - imports */
import { arrowRight } from 'assets/icons';

/* styles - imports */
import './DetailAudienceStyle.scss';

class DetailAudience extends PureComponent {
  constructor(props) {
    super(props);
    this.onDescription = this.onDescription.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.description = this.description.bind(this);
    this.renderAudience = this.renderAudience.bind(this);
    this.renderDocuments = this.renderDocuments.bind(this);
    this.renderStatusOpen = this.renderStatusOpen.bind(this);
    this.renderStatusPostulatoryStage = this.renderStatusPostulatoryStage.bind(
      this,
    );
    this.renderStatusInVoting = this.renderStatusInVoting.bind(this);
    this.renderStatusResult = this.renderStatusResult.bind(this);
    this.state = { showMoreDescription: false };
  }

  onDescription() {
    this.setState({ showMoreDescription: !this.state.showMoreDescription });
  }

  changeDescription(description) {
    if (description.length > 320)
      return this.state.showMoreDescription
        ? description
        : `${description.substr(0, 320)}(...)`;
    return description;
  }

  renderButtonMoreDescription(description) {
    const { showMoreDescription } = this.state;
    if (description.length > 320)
      return (
        <Link
          href="#showMoreDescription"
          className="da--link"
          onClick={this.onDescription}
        >
          {showMoreDescription ? 'Ler menos' : 'Ler mais'}
        </Link>
      );
    return '';
  }

  description() {
    const { description } = this.props;
    return (
      <div className="da--description">
        <p className="secondary title">DESCRIÇÃO</p>
        <p className="primary description">
          {this.changeDescription(description)}
        </p>
        {this.renderButtonMoreDescription(description)}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderDocuments() {
    const { documents } = this.props;
    return documents.map((document, index) => (
      <AudienceRecord
        key={index}
        icon={GET_ICON(document.document_type)}
        title={document.name}
        description={`Última atualização em ${document.updated_at}`}
        url={
          document.document_type !== 'video'
            ? `${CONSTANTS.IMAGE_URL}${document.filename.url}`
            : document.url_video
        }
      />
    ));
  }

  // eslint-disable-next-line class-methods-use-this
  renderStatusOpen({ name, status, startAt, endAt, id, audienceId }) {
    return status === 'active' ? (
      <div className="da--steps-box">
        <div className="bx--row">
          <div className="bx--col-sm-4 steps-row">
            <span className="label-status label-status--open">{name}</span>
          </div>
          <div className="bx--col-sm-4 steps-row">
            <p className="steps-description">
              {`O edital encontra-se aberto para a realização de pedidos até o dia ${
                endAt.split(' ')[0]
              }`}
            </p>
          </div>
          <div className="bx--col-sm-4 steps-row">
            <Button href={urls.AUDIENCE_REQUESTS(audienceId, id)}>
              Visualizar Pedidos
              {arrowRight()}
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="bx--row">
        <div className="bx--col-sm-4 steps-row">
          <p className="steps--title">
            <span className="label-status label-status--open">{name}</span>
            {` ${startAt} até ${endAt}`}
          </p>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderStatusPostulatoryStage({
    name,
    status,
    startAt,
    endAt,
    audienceId,
    id,
  }) {
    return status === 'active' ? (
      <div className="da--steps-box">
        <div className="bx--row">
          <div className="bx--col-sm-4 steps-row">
            <span className="label-status label-status--postulatory_stage">
              {name}
            </span>
          </div>
          {/* <div className="bx--col-sm-4 steps-row">
            <p className="steps-description">....</p>
          </div> */}
          <div className="bx--col-sm-4 steps-row">
            <Button href={urls.AUDIENCE_POSTULATORY_STAGE(audienceId, id)}>
              Visualizar Pedidos
              {arrowRight()}
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="bx--row">
        <div className="bx--col-sm-4 steps-row">
          <p className="steps--title">
            <span className="label-status label-status--postulatory_stage">
              {name}
            </span>
            {` ${startAt} até ${endAt}`}
          </p>
        </div>
      </div>
    );
  }

  renderStatusInVoting({ name, status, startAt, endAt, audienceId, id }) {
    const { isVoted, auth, onModalSignIn, onModalSignUp } = this.props;
    return status === 'active' ? (
      <div className="da--steps-box">
        <div className="bx--row">
          <div className="bx--col-sm-4 steps-row">
            <span className="label-status label-status--in_voting">{name}</span>
          </div>
          <div className="bx--col-sm-4 steps-row">
            <p className="steps-description">
              {`Esta audiência foi finalizada e agora o período de votação dos
              pedidos está aberto até às ${endAt.split(' ')[1]} do dia ${
                endAt.split(' ')[0]
              }`}
            </p>
          </div>
          <div className="bx--col-sm-4 steps-row">
            {auth ? (
              <Button
                disabled={isVoted}
                href={urls.AUDIENCE_VOTE(audienceId, id)}
              >
                Participar da votação
                {arrowRight()}
              </Button>
            ) : (
              <p className="dr--input">
                <Link href="#signIn" onClick={() => onModalSignIn()}>
                  Faça login
                </Link>
                {' ou '}
                <Link href="#signIn" onClick={() => onModalSignUp()}>
                  cadastre-se
                </Link>
                {' para participar da votação'}
              </p>
            )}
          </div>
          {isVoted && (
            <div className="bx--col-sm-4 steps-row">
              <p className="">
                Votos já computados, aguarde o termino da votação.
              </p>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className="bx--row">
        <div className="bx--col-sm-4 steps-row">
          <p className="steps--title">
            <span className="label-status label-status--in_voting">{name}</span>
            {` ${startAt} até ${endAt}`}
          </p>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderStatusResult({ name, status, startAt, endAt, audienceId, id }) {
    return status === 'active' ? (
      <div className="da--steps-box">
        <div className="bx--row">
          <div className="bx--col-sm-4 steps-row">
            <span className="label-status label-status--result">{name}</span>
          </div>
          <div className="bx--col-sm-4 steps-row">
            <p className="steps-description">....</p>
          </div>
          <div className="bx--col-sm-4 steps-row">
            <Button href={urls.AUDIENCE_RESULT(audienceId, id)}>
              Resultado
              {arrowRight()}
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="bx--row">
        <div className="bx--col-sm-4 steps-row">
          <p className="steps--title">
            <span className="label-status label-status--result">{name}</span>
            {` ${startAt} até ${endAt}`}
          </p>
        </div>
      </div>
    );
  }

  renderSteps() {
    const { stages } = this.props;
    return (
      <div className="da--steps">
        <p className="secondary title">FASES</p>
        {stages.map(stage => {
          switch (stage.stageType) {
            case 'open_for_orders':
              return this.renderStatusOpen(stage);
            case 'postulatory_stage':
              return this.renderStatusPostulatoryStage(stage);
            case 'in_voting':
              return this.renderStatusInVoting(stage);
            case 'result':
              return this.renderStatusResult(stage);
            default:
              return null;
          }
        })}
      </div>
    );
  }

  renderAudience() {
    const {
      onlyDate,
      onlyHour,
      street,
      number,
      neighborhood,
      city,
      state,
      zipCode,
      error,
    } = this.props;

    return error === null ? (
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col-sm-04 bx--offset-lg-3 bx--col-xlg-10">
            {this.description()}
          </div>
          <div className="bx--col-sm-04 bx--offset-lg-3 bx--col-xlg-10">
            <div className="bx--row">
              <div className="bx--col">
                <AudienceInformation
                  date={onlyDate}
                  hour={onlyHour}
                  address={
                    street !== null && street !== ''
                      ? `${street}, ${number}, ${neighborhood}. ${city} ${state}, ${zipCode}`
                      : null
                  }
                />
              </div>
            </div>
            {this.renderDocuments()}
            {this.renderSteps()}
          </div>
        </div>
      </div>
    ) : (
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col text-center">
            <p className="primary description label-error">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      auth,
      isLoading,
      title,
      owner,
      onSignOut,
      onModalSignIn,
    } = this.props;

    return [
      <Header
        title={title}
        subtitle={owner}
        auth={auth}
        onSignOut={onSignOut}
        onModalSignIn={onModalSignIn}
      />,
      <div className="detail-audience">
        {isLoading ? <Loading /> : this.renderAudience()}
      </div>,
    ];
  }
}

DetailAudience.propTypes = {
  description: PropTypes.string,
  stages: PropTypes.array,
  documents: PropTypes.arrayOf({
    id: PropTypes.number.isRequired,
    document_type: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }),
};

DetailAudience.defaultProps = {
  description: '',
  stages: [],
  documents: [],
};

export default DetailAudience;
