import validateCPF from './CPF';

const required = (value) => {
  if (value && value.length !== 0 && value.trim().length !== 0)
    return { value, message: '' };
  return { value, message: 'Campo não pode ficar em branco.' };
};

const requiredBoolean = (value) => {
  if (value) return { value, message: '' };
  return { value, message: 'Campo não pode ficar em branco.' };
};

const number = (value) => {
  if (Number.isInteger(value)) return { value, message: '' };
  return { value, message: 'Campo não pode ficar em branco.' };
};

const email = (value) => {
  // eslint-disable-next-line
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(value).toLowerCase())) return { value, message: '' };
  return {
    value,
    message: 'Por favor digite um e-mail válido.',
  };
};

const password = (value) => {
  const re = /^.{6,}$/;
  if (re.test(String(value))) return { value, message: '' };
  return {
    value,
    message:
      'Senha inválida. A senha deve satisfazer os requisitos de segurança.',
  };
};

const cpf = (value) => {
  const CPF = value.replace('.', '').replace('.', '').replace('-', '');
  if (validateCPF(CPF)) return { value, message: '' };
  return { value, message: 'CPF inválido.' };
};

const switchValidations = (elem, value) => {
  switch (elem) {
    case 'required':
      return required(value);
    case 'requiredBoolean':
      return requiredBoolean(value);
    case 'number':
      return number(value);
    // case 'requiredObject':
    //   return requiredObject(value);
    case 'email':
      return email(value);
    case 'password':
      return password(value);
    case 'cpf':
      return cpf(value);
    // case 'date':
    //   return date(value);
    // case 'time':
    //   return time(value);
    default:
      return true;
  }
};

const runValidations = (elem) => {
  let i = 0;
  let field = elem;
  while (i !== elem.validations.length) {
    field = switchValidations(elem.validations[i], elem.value);
    if (field.message === '') i += 1;
    else break;
  }
  return { ...field, validations: elem.validations };
};

export default runValidations;
