import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as authActions } from 'store/ducks/auth';
import { Creators as ordersActions } from 'store/ducks/orders';

/* modal components - imports */
import SignInContainer from 'containers/SignInContainer';
import SignUpContainer from 'containers/SignUpContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';

/* presentational components - imports */
import DetailRequest from 'components/presentational/DetailRequest';

/* utils - imports */
import INPUTS from 'utils/inputs';

/* config - imports */
import { history } from 'config/routes';

class DetailRequestContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.onSignOut = this.onSignOut.bind(this);
    this.onModalSignIn = this.onModalSignIn.bind(this);
    this.onModalSignUp = this.onModalSignUp.bind(this);
    this.onModalForgotPassword = this.onModalForgotPassword.bind(this);
    this.onLike = this.onLike.bind(this);
    this.onComment = this.onComment.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      modalSignIn: false,
      modalSignUp: false,
      onCallback: () => {},
      comment: {
        value: '',
        message: '',
        validations: ['required'],
      },
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.getOrder(params.requestId);
  }

  async getOrder(id) {
    const { order } = this.props;
    await order({ id });
  }

  async onModalSignIn(onCallback = () => {}) {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignUp) await this.onModalSignUp();
    this.setState({
      modalSignIn: !modalSignIn,
      modalForgotPassword: false,
      onCallback,
    });
  }

  async onModalSignUp() {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignIn) await this.onModalSignIn();
    this.setState({ modalSignUp: !modalSignUp, modalForgotPassword: false });
  }

  async onModalForgotPassword(onCallback = () => {}) {
    const { modalForgotPassword } = this.state;
    this.setState({
      modalForgotPassword: !modalForgotPassword,
      modalSignIn: false,
      onCallback,
    });
  }

  async onSignOut() {
    const { signOut } = this.props;
    await signOut();
  }

  async onLike(id) {
    const { auth, orderLike } = this.props;
    if (auth) {
      await orderLike(id);
    } else {
      this.onModalSignIn(() => orderLike(id));
    }
  }

  async onComment(id) {
    let { comment } = this.state;
    const { auth, orderComment } = this.props;

    comment = INPUTS.onValidate(comment, comment.value);

    if (comment.message === '' && auth) {
      await orderComment({ id, comment: comment.value });
      comment.value = '';
    }

    this.setState({ comment });
  }

  onChange(name, value) {
    if (this.state[name].value !== value)
      this.setState(INPUTS.onChange(name, this.state[name], value));
  }

  render() {
    const {
      modalSignIn,
      modalSignUp,
      modalForgotPassword,
      onCallback,
      comment,
    } = this.state;
    const {
      match: { params },
      auth,
      detailRequest,
      isLoading,
    } = this.props;

    return [
      <DetailRequest
        key="detailRequestContainer"
        isLoading={isLoading}
        history={history}
        detailRequest={detailRequest}
        comment={comment}
        auth={auth}
        onSignOut={this.onSignOut}
        onLike={this.onLike}
        onComment={this.onComment}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onChange={this.onChange}
        audienceId={params.audienceId}
        stageId={params.stageId}
      />,
      <SignInContainer
        modalSignIn={modalSignIn}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
      <SignUpContainer
        modalSignUp={modalSignUp}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
      />,
      <ForgotPasswordContainer
        modalForgotPassword={modalForgotPassword}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  detailRequest: state.orders.order,
  isLoading: state.orders.isLoading,
});

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(authActions.signOut()),
  order: payload => dispatch(ordersActions.order(payload)),
  orderLike: id => dispatch(ordersActions.orderLike(id)),
  orderComment: payload => dispatch(ordersActions.orderComment(payload)),
});

DetailRequestContainer.propTypes = {
  order: PropTypes.func.isRequired,
  orderLike: PropTypes.func.isRequired,
  orderComment: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  detailRequest: PropTypes.object,
  isLoading: PropTypes.bool,
};

DetailRequestContainer.defaultProps = {
  isLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailRequestContainer);
