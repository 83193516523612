import { takeLatest, put } from 'redux-saga/effects';
import ENDPOINTS from 'config/endpoints';
import axios from 'axios';

import { Types } from '../ducks/votes';

function create(data) {
  const accessToken = localStorage.getItem('accessToken');
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');

  return axios({
    method: 'POST',
    url: ENDPOINTS.CREATE_VOTE,
    headers: {
      'access-token': accessToken,
      client,
      uid,
    },
    data,
  })
    .then(res => res)
    .catch(e => {
      const response =
        e.response.status === 404 ? e.response : e.response.data.errors;
      throw response;
    });
}

function* createVote({ payload, finish }) {
  try {
    yield create(payload);
    finish();
    yield put({
      type: Types.CREATE_VOTE_SUCCESS,
    });
  } catch (messages) {
    yield put({
      type: Types.CREATE_VOTE_FAIL,
      message: messages,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.CREATE_VOTE, createVote);
}
