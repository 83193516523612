import React from 'react';

/* carbon - imports */
import { Button } from 'carbon-components-react';

/* config - imports */
import * as urls from 'config/urls';

/* assets - imports */
import { checkMark, checkMarkCircle } from 'assets/icons';

/* styles - imports */
import 'components/presentational/NewRequest/NewRequestStyle.scss';

const Success = ({ audienceId }) => (
  <div className="new-request">
    <div className="bx--grid">
      <div className="bx--row">
        <div className="bx--col-sm-4 bx--offset-md-2 bx--col-md-4 bx--offset-lg-5 bx--col-lg-6">
          <div className="nr success">
            {checkMarkCircle()}
            <h3>Seu voto foi recebido.</h3>
            <p className="default">
              Após o final do período de votação, você poderá visualizar o
              resultado final.
            </p>
          </div>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <div className="bx--btn--container-center">
            <Button href={urls.AUDIENCE(audienceId)}>
              OK, entedido
              {checkMark()}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Success;
