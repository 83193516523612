import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  audiencesList: ['payload'],
  audiencesListSuccess: ['payload', 'isLoading'],
  audiencesListFail: ['isLoading', 'error'],

  audience: ['payload'],
  audienceSuccess: ['payload', 'isLoading'],
  audienceFail: ['isLoading', 'error'],
});

const INITIAL_STATE = {
  items: [],
  isLoading: false,
  error: null,
  // audience: { }
};

const requestAudiencesList = state => ({
  ...state,
  isLoading: true,
  error: null,
});
const requestAudiencesListSuccess = (state, payload) => ({
  ...state,
  items: payload.items,
  isLoading: false,
});
const requestAudiencesListFail = (state, error) => ({
  ...state,
  isLoading: false,
  error: error.error,
});

const requestAudience = state => ({
  ...state,
  isLoading: true,
  error: null,
});
const requestAudienceSuccess = (state, payload) => ({
  ...state,
  audience: payload.audience,
  isLoading: false,
});
const requestAudienceFail = (state, error) => ({
  ...state,
  isLoading: false,
  error: error.error,
});

export default createReducer(INITIAL_STATE, {
  [Types.AUDIENCES_LIST]: requestAudiencesList,
  [Types.AUDIENCES_LIST_SUCCESS]: requestAudiencesListSuccess,
  [Types.AUDIENCES_LIST_FAIL]: requestAudiencesListFail,

  [Types.AUDIENCE]: requestAudience,
  [Types.AUDIENCE_SUCCESS]: requestAudienceSuccess,
  [Types.AUDIENCE_FAIL]: requestAudienceFail,
});
