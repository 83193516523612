import React from 'react';
import PropTypes from 'prop-types';

import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

/* containers - imports */
import DashboardContainer from 'containers/DashboardContainer';
import DetailAudienceContainer from 'containers/DetailAudienceContainer';
import AudienceRequestsContainer from 'containers/AudienceRequestsContainer';
import AudiencePostulatoryContainer from 'containers/AudiencePostulatoryContainer';
import AudienceVoteContainer from 'containers/AudienceVoteContainer';
import AudienceResultContainer from 'containers/AudienceResultContainer';
import DetailRequestContainer from 'containers/DetailRequestContainer';
import NewRequestContainer from 'containers/NewRequestContainer';

/* config - imports */
import isAuthenticated from './auth';

/* styles - imports */
import 'sass/global.scss';

export const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/" component={DashboardContainer} />
      <Route exact path="/audiencias" component={DashboardContainer} />
      <Route
        exact
        path="/audiencias/:audienceId"
        component={DetailAudienceContainer}
      />
      <Route
        exact
        path="/audiencias/:audienceId/fases/:stageId/pedidos"
        component={AudienceRequestsContainer}
      />
      <Route
        exact
        path="/audiencias/:audienceId/fases/:stageId/etapa-postulatoria"
        component={AudiencePostulatoryContainer}
      />
      <Route
        exact
        path="/audiencias/:audienceId/fases/:stageId/pedidos/:requestId"
        component={DetailRequestContainer}
      />
      <PrivateRoute
        exact
        path="/audiencias/:audienceId/fases/:stageId/novo-pedido"
        component={NewRequestContainer}
      />
      <PrivateRoute
        exact
        path="/audiencias/:audienceId/fases/:stageId/votacao"
        component={AudienceVoteContainer}
      />
      <Route
        exact
        path="/audiencias/:audienceId/fases/:stageId/resultado"
        component={AudienceResultContainer}
      />
      <PrivateRoute path="/app" component={() => <h1>Você está logado</h1>} />
    </Switch>
  </ConnectedRouter>
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.string,
};

PrivateRoute.defaultProps = {};

export default Routes;
