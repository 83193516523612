export const DASHBOARD = '/';
export const SIGN_UP = '/cadastro';
export const SIGN_IN = '/login';
export const AUDIENCES = '/audiencias';
export const AUDIENCE = audienceId => `/audiencias/${audienceId}`;
export const AUDIENCE_REQUESTS = (audienceId, stageId) =>
  `/audiencias/${audienceId}/fases/${stageId}/pedidos`;
export const AUDIENCE_POSTULATORY_STAGE = (audienceId, stageId) =>
  `/audiencias/${audienceId}/fases/${stageId}/etapa-postulatoria`;
export const AUDIENCE_REQUEST = (audienceId, requestId, stageId) =>
  `/audiencias/${audienceId}/fases/${stageId}/pedidos/${requestId}`;
export const AUDIENCE_VOTE = (audienceId, stageId) =>
  `/audiencias/${audienceId}/fases/${stageId}/votacao`;
export const AUDIENCE_RESULT = (audienceId, stageId) =>
  `/audiencias/${audienceId}/fases/${stageId}/resultado`;
