import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import {
  Form,
  FormGroup,
  TextInput,
  Select,
  SelectItem,
  FileUploaderButton,
  Button,
} from 'carbon-components-react';

/* assets - imports */
import { checkMark } from 'assets/icons';

/* styles - imports */
import '../NewRequestStyle.scss';

class AttachEvidence extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      name: '',
      evidenceType: '',
      url: '',
      filename: null,
    };
  }

  onChange(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const { name, evidenceType, url, filename } = this.state;
    const { addEvidence } = this.props;

    return (
      <div className="bx--grid nr evidences">
        <div className="bx--row">
          <div
            className="bx--col-sm-4 bx--offset-md-2
            bx--col-md-4 bx--offset-lg-4 bx--col-lg-8"
          >
            <Form>
              <FormGroup legendText="Título da prova">
                <TextInput
                  labelText=""
                  placeholder="Escolha um nome para referência"
                  maxLength={255}
                  value={name}
                  onChange={event => this.onChange('name', event.target.value)}
                />
              </FormGroup>
              <Select
                value={evidenceType}
                labelText="Tipo de prova"
                onKeyUp={event =>
                  this.onValidate('evidenceType', event.target.value)
                }
                onChange={event => {
                  if (event.target.value === 'video')
                    this.onChange('filename', null);
                  else this.onChange('url', '');
                  this.onChange('evidenceType', event.target.value);
                }}
              >
                <SelectItem disabled value="" text="Selecione" />
                <SelectItem value="pdf" text="Documento PDF" />
                <SelectItem value="image" text="Imagem" />
                <SelectItem value="video" text="Vídeo" />
              </Select>
              {evidenceType !== '' && evidenceType !== 'video' && (
                <FormGroup legendText="">
                  <FileUploaderButton
                    name="evidenceFile"
                    className="bx--file-uploader-button"
                    buttonKind="tertiary"
                    labelText="Anexar Arquivo"
                    value={filename}
                    onChange={event =>
                      this.onChange('filename', event.target.files[0])
                    }
                  />
                </FormGroup>
              )}
              {evidenceType !== '' && evidenceType === 'video' && (
                <FormGroup legendText="Vídeo">
                  <TextInput
                    labelText=""
                    placeholder="URL do vídeo"
                    maxLength={255}
                    value={url}
                    onChange={event => this.onChange('url', event.target.value)}
                  />
                </FormGroup>
              )}
            </Form>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <div className="bx--btn--container-center">
              <Button
                disabled={name === '' || !(filename !== null || url !== '')}
                onClick={() =>
                  addEvidence({ name, filename, evidenceType, url })
                }
              >
                Anexar Arquivo
                {checkMark()}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AttachEvidence.propTypes = { addEvidence: PropTypes.func.isRequired };

export default AttachEvidence;
