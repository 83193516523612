import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import {
  Form,
  FormGroup,
  TextArea,
  Checkbox,
  Button,
} from 'carbon-components-react';

/* utils - imports */
import INPUTS from 'utils/inputs';

/* assets - imports */
import { checkMark } from 'assets/icons';

/* styles - imports */
import '../NewRequestStyle.scss';

class RequestProofsExpert extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      typeProof: 'expert',
      goal: {
        value: '',
        message: '',
        validations: ['required'],
      },
      description: {
        value: '',
        message: '',
        validations: ['required'],
      },
      confirm: {
        value: false,
        message: '',
        validations: ['requiredBoolean'],
      },
    };
  }

  onChange(name, value) {
    if (this.state[name].value !== value)
      this.setState(INPUTS.onChange(name, this.state[name], value));
  }

  onValidate(name, value) {
    this.setState({ [name]: INPUTS.onValidate(this.state[name], value) });
  }

  onSubmit() {
    let { description, goal, confirm } = this.state;
    const { typeProof } = this.state;
    const { addRequestProof } = this.props;

    description = INPUTS.onValidate(description, description.value);
    goal = INPUTS.onValidate(goal, goal.value);
    confirm = INPUTS.onValidate(confirm, confirm.value);

    if (
      description.message === '' &&
      goal.message === '' &&
      confirm.message === ''
    )
      addRequestProof({
        typeProof,
        object_to_prove: description.value,
        evidence_to_prove: goal.value,
        confirm_information: confirm.value,
        evidence_type: 'expert',
      });
    else this.setState({ description, goal, confirm });
  }

  render() {
    const { goal, description, confirm } = this.state;

    return (
      <div className="bx--grid">
        <div className="bx--row nr request-proofs-testimonial">
          <div className="bx--col-sm-4 bx--offset-md-2 bx--col-md-4 bx--offset-lg-4 bx--col-lg-8">
            <Form>
              <p className="title">
                Texto de suporte explicando sobre a prova pericial
              </p>

              <FormGroup legendText="Qual o objeto?">
                <TextArea
                  placeholder="Qual o objeto da perícia?"
                  cols={50}
                  rows={4}
                  value={goal.value}
                  labelText=""
                  invalidText={goal.message}
                  invalid={goal.message !== ''}
                  onKeyUp={event => this.onValidate('goal', event.target.value)}
                  onChange={event => this.onChange('goal', event.target.value)}
                />
              </FormGroup>
              <FormGroup legendText="O que você quer provar?">
                <TextArea
                  placeholder="O que você quer provar com esta perícia?"
                  cols={50}
                  rows={4}
                  value={description.value}
                  labelText=""
                  invalidText={description.message}
                  invalid={description.message !== ''}
                  onKeyUp={event =>
                    this.onValidate('description', event.target.value)
                  }
                  onChange={event =>
                    this.onChange('description', event.target.value)
                  }
                />
              </FormGroup>
              <Checkbox
                id="confirm"
                name="confirm"
                labelText="Confirmo a veracidade das informações prestadas."
                checked={confirm.value}
                onChange={value => this.onChange('confirm', value)}
              />
              <div className="bx--row">
                <div className="bx--col">
                  <div className="bx--btn--container-center">
                    <Button href="#" onClick={() => this.onSubmit()}>
                      Solicitar testemunho
                      {checkMark()}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

RequestProofsExpert.propTypes = {
  addRequestProof: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RequestProofsExpert;
