import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as authActions } from 'store/ducks/auth';
import { Creators as addressesActions } from 'store/ducks/addresses';
import { Creators as ordersActions } from 'store/ducks/orders';
import { Creators as audiencesActions } from 'store/ducks/audiences';

/* modal components - imports */
import SignInContainer from 'containers/SignInContainer';
import SignUpContainer from 'containers/SignUpContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';

/* presentational components - imports */
import NewRequest from 'components/presentational/NewRequest';

/* utils - imports */
import INPUTS from 'utils/inputs';

/* config - imports */
import { history } from 'config/routes';

class NewRequestContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.onModalSignIn = this.onModalSignIn.bind(this);
    this.onModalSignUp = this.onModalSignUp.bind(this);
    this.onModalForgotPassword = this.onModalForgotPassword.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onZipCode = this.onZipCode.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setForm = this.setForm.bind(this);
    this.nextForm = this.nextForm.bind(this);
    this.prevForm = this.prevForm.bind(this);
    this.addEvidence = this.addEvidence.bind(this);
    this.removeEvidence = this.removeEvidence.bind(this);
    this.addRequestProof = this.addRequestProof.bind(this);
    this.removeRequestProof = this.removeRequestProof.bind(this);
    this.state = {
      modalSignIn: false,
      modalSignUp: false,
      modalForgotPassword: false,
      onCallback: () => {},
      index: 0,
      title: [
        'Quem é você?',
        'Enviar novo Pedido',
        'Anexar Prova',
        'Solicitar Prova',
        'Solicitar Prova Testemunhal',
        'Solicitar Prova Pericial',
        'Solicitar Prova Documental',
        'Pedido Enviado',
      ],
      form: {
        answerId: {
          value: 0,
          message: '',
          validations: ['number'],
        },
        openField: {
          value: '',
          message: '',
          validations: [],
        },
        isOpenField: {
          value: false,
          message: '',
          validations: [],
        },
        description: {
          value: '',
          message: '',
          validations: ['required'],
        },
        fundamentals: {
          value: '',
          message: '',
          validations: ['required'],
        },
        favorable: {
          value: '',
          message: '',
          validations: ['required'],
        },
        filenameName: {
          value: '',
          message: '',
          validations: [],
        },
        filenameType: {
          value: '',
          message: '',
          validations: [],
        },
        filenameUrl: {
          value: '',
          message: '',
          validations: [],
        },
        filename: {
          value: null,
          message: '',
          validations: [],
        },
        attachEvidences: [],
        requestProofs: [],
      },
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.getAudience(params.audienceId);
  }

  async getAudience(id) {
    const { audience } = this.props;
    await audience({ id });
  }

  async onModalSignIn(onCallback = () => {}) {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignUp) await this.onModalSignUp();
    this.setState({
      modalSignIn: !modalSignIn,
      modalForgotPassword: false,
      onCallback,
    });
  }

  async onModalSignUp() {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignIn) await this.onModalSignIn();
    this.setState({ modalSignUp: !modalSignUp, modalForgotPassword: false });
  }

  async onModalForgotPassword(onCallback = () => {}) {
    const { modalForgotPassword } = this.state;
    this.setState({
      modalForgotPassword: !modalForgotPassword,
      modalSignIn: false,
      onCallback,
    });
  }

  async onSignOut() {
    const { signOut } = this.props;
    await signOut();
    history.goBack();
  }

  async onZipCode(zipCode) {
    const { getAddress, address } = this.props;
    const value = zipCode.replace(/[^0-9.]/g, '');

    if (
      value.length === 8 &&
      ((address !== null && address.zip_code !== value) || address === null)
    ) {
      await getAddress(value);
    }
  }

  onChange(name, value) {
    const { form } = this.state;

    if (form[name].value !== value)
      this.setState({
        form: {
          ...form,
          ...INPUTS.onChange(name, form[name], value),
        },
      });
  }

  onValidate(name, value) {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        ...{ [name]: INPUTS.onValidate(form[name], value) },
      },
    });
  }

  onSubmit() {
    let { answerId, description, fundamentals, favorable } = this.state.form;
    const {
      filenameName,
      filenameType,
      filenameUrl,
      filename,
      attachEvidences,
      requestProofs,
      openField,
    } = this.state.form;
    const {
      createOrder,
      match: { params },
    } = this.props;

    answerId = INPUTS.onValidate(answerId, answerId.value);
    description = INPUTS.onValidate(description, description.value);
    fundamentals = INPUTS.onValidate(fundamentals, fundamentals.value);
    favorable = INPUTS.onValidate(favorable, favorable.value);

    if (
      answerId.message === '' &&
      description.message === '' &&
      fundamentals.message === '' &&
      favorable.message === ''
    ) {
      const formData = new FormData();
      formData.append('answer_attributes[0][option_id]', answerId.value);
      if (openField.value !== '')
        formData.append('answer_attributes[0][open_field]', openField.value);
      formData.append('description', description.value);
      formData.append('fundamentals', fundamentals.value);
      formData.append('filename_name', filenameName.value);
      formData.append('filename_type', filenameType.value);
      formData.append('filename_url', filenameUrl.value);
      formData.append('filename', filename.value);
      formData.append('favorable', favorable.value);
      formData.append('audience_id', params.audienceId);
      formData.append('stage_id', params.stageId);
      attachEvidences.forEach((evidence, index) => {
        formData.append(`evidences_attributes[${index}][name]`, evidence.name);
        formData.append(
          `evidences_attributes[${index}][evidence_type]`,
          evidence.evidenceType,
        );
        if (evidence.evidenceType !== 'video')
          formData.append(
            `evidences_attributes[${index}][filename]`,
            evidence.filename,
          );
        else
          formData.append(
            `evidences_attributes[${index}][url_video]`,
            evidence.url,
          );
      });
      requestProofs.forEach((proof, index) => {
        if (proof.typeProof === 'testimony') {
          formData.append(
            `request_evidences_attributes[${index}][description]`,
            proof.description,
          );
          formData.append(
            `request_evidences_attributes[${index}][witness_name]`,
            proof.witness_name,
          );
          formData.append(
            `request_evidences_attributes[${index}][cpf]`,
            proof.cpf,
          );
          formData.append(
            `request_evidences_attributes[${index}][zip_code]`,
            proof.zip_code,
          );
          formData.append(
            `request_evidences_attributes[${index}][street]`,
            proof.street,
          );
          formData.append(
            `request_evidences_attributes[${index}][number]`,
            proof.number,
          );
          formData.append(
            `request_evidences_attributes[${index}][complement]`,
            proof.complement,
          );
          formData.append(
            `request_evidences_attributes[${index}][neighborhood]`,
            proof.neighborhood,
          );
          formData.append(
            `request_evidences_attributes[${index}][city]`,
            proof.city,
          );
          formData.append(
            `request_evidences_attributes[${index}][state]`,
            proof.state,
          );
        } else if (proof.typeProof === 'documentary') {
          formData.append(
            `request_evidences_attributes[${index}][document_description]`,
            proof.document_description,
          );
          formData.append(
            `request_evidences_attributes[${index}][owner_document]`,
            proof.owner_document,
          );
          formData.append(
            `request_evidences_attributes[${index}][evidence_to_prove]`,
            proof.evidence_to_prove,
          );
        } else if (proof.typeProof === 'expert') {
          formData.append(
            `request_evidences_attributes[${index}][object_to_prove]`,
            proof.object_to_prove,
          );
          formData.append(
            `request_evidences_attributes[${index}][evidence_to_prove]`,
            proof.evidence_to_prove,
          );
        }

        formData.append(
          `request_evidences_attributes[${index}][request_evidence_type]`,
          proof.evidence_type,
        );
        formData.append(
          `request_evidences_attributes[${index}][confirm_information]`,
          proof.confirm_information,
        );
      });

      createOrder(formData, () => this.setForm(7));
    } else {
      this.setState({
        form: {
          ...this.state.form,
          answerId,
          description,
          fundamentals,
          favorable,
        },
      });
    }
  }

  setForm(index) {
    this.setState({ index });
  }

  nextForm() {
    this.setState({ index: 1 });
  }

  prevForm() {
    const { index } = this.state;

    if (index === 3) this.setState({ index: 1 });
    else if (index === 4 || index === 5 || index === 6)
      this.setState({ index: 3 });
    else if (index !== 0) this.setState({ index: index - 1 });
    else history.goBack();
  }

  addEvidence(evidence) {
    const { form } = this.state;

    this.setState({
      index: 1,
      form: {
        ...form,
        attachEvidences: [...form.attachEvidences, evidence],
      },
    });
  }

  removeEvidence(evidence) {
    const { form } = this.state;

    const index = form.attachEvidences.indexOf(evidence);

    if (index > -1) {
      form.attachEvidences.splice(index, 1);
      this.setState({ form });
    }
  }

  addRequestProof(proof) {
    const { form } = this.state;

    this.setState({
      index: 1,
      form: {
        ...form,
        requestProofs: [...form.requestProofs, proof],
      },
    });
  }

  removeRequestProof(proof) {
    const { form } = this.state;

    const index = form.requestProofs.indexOf(proof);

    if (index > -1) {
      form.requestProofs.splice(index, 1);
      this.setState({ form });
    }
  }

  render() {
    const {
      modalSignIn,
      modalSignUp,
      modalForgotPassword,
      onCallback,
    } = this.state;
    const {
      isLoading,
      success,
      message,
      auth,
      address,
      isLoadingAddress,
      match: { params },
    } = this.props;

    return [
      <NewRequest
        key="newRequest"
        history={history}
        onChange={this.onChange}
        onValidate={this.onValidate}
        onSubmit={this.onSubmit}
        setForm={this.setForm}
        nextForm={this.nextForm}
        prevForm={this.prevForm}
        addEvidence={this.addEvidence}
        removeEvidence={this.removeEvidence}
        addRequestProof={this.addRequestProof}
        removeRequestProof={this.removeRequestProof}
        detailAudience={this.props.detailAudience}
        onZipCode={this.onZipCode}
        address={address}
        isLoading={isLoading}
        isLoadingAddress={isLoadingAddress}
        success={success}
        message={message}
        {...this.state}
        auth={auth}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onSignOut={this.onSignOut}
        audienceId={params.audienceId}
      />,
      <SignInContainer
        modalSignIn={modalSignIn}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
      <SignUpContainer
        modalSignUp={modalSignUp}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
      />,
      <ForgotPasswordContainer
        modalForgotPassword={modalForgotPassword}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  isLoading: state.orders.isLoading || state.audience.isLoading,
  success: state.orders.success,
  message: state.orders.message,
  detailAudience: state.audience.audience,
  order: state.orders.order,
  address: state.addresses.address,
  isLoadingAddress: state.addresses.isLoading,
});

const mapDispatchToProps = dispatch => ({
  getAddress: payload => dispatch(addressesActions.address(payload)),
  signOut: () => dispatch(authActions.signOut()),
  audience: payload => dispatch(audiencesActions.audience(payload)),
  createOrder: (payload, finish) =>
    dispatch(ordersActions.createOrder(payload, finish)),
});

NewRequestContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  audience: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  detailAudience: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  zipCode: PropTypes.func.isRequired,
  isLoadingAddress: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.arrayOf(PropTypes.string).isRequired,
};

NewRequestContainer.defaultProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewRequestContainer);
