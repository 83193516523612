import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import {
  Form,
  FormGroup,
  TextInput,
  Link,
  InlineNotification,
} from 'carbon-components-react';

/* styles - imports */
import './SignInStyle.scss';

const SignIn = ({
  success,
  message,
  onChange,
  onValidate,
  email,
  password,
  onModalSignUp,
  onModalForgotPassword,
}) => {
  return (
    <div className="nr sign-in">
      <div className="si">
        <Form invalid invalidText={email.message}>
          <FormGroup legendText="E-mail">
            <TextInput
              maxLength={255}
              value={email.value}
              labelText=""
              invalidText={email.message}
              invalid={email.message !== ''}
              onKeyUp={event => onValidate('email', event.target.value)}
              onChange={event => onChange('email', event.target.value)}
            />
          </FormGroup>
          <FormGroup legendText="Senha">
            <TextInput
              type="password"
              maxLength={26}
              minLength={8}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
              value={password.value}
              labelText=""
              invalidText={password.message}
              invalid={password.message !== ''}
              onKeyUp={event => onValidate('password', event.target.value)}
              onChange={event => onChange('password', event.target.value)}
            />
            <div className="si--forget-password">
              <Link href="#" onClick={onModalForgotPassword}>
                Esqueci minha senha
              </Link>
            </div>
          </FormGroup>
          <div className="bx--row">
            <div className="bx--col-sm-4">
              {!success && message.length !== 0 && (
                <InlineNotification
                  title="Ops, ocorreu algum erro!"
                  subtitle={message.join('\n')}
                  kind="error"
                />
              )}
            </div>
            <div className="bx--col-sm-4">
              <p className="si--sign-up">
                Não tem uma conta?{' '}
                <Link href="#" onClick={onModalSignUp}>
                  Cadastre-se aqui
                </Link>
              </p>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  authSignIn: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SignIn;
