import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import {
  Form,
  FormGroup,
  RadioButton,
  Button,
  TextInput,
} from 'carbon-components-react';

/* core components - imports */
import Loading from 'components/core/Loading';

/* assets - imports */
import { arrowRight } from 'assets/icons';

/* styles - imports */
import '../NewRequestStyle.scss';

const radioChange = async (name, value, isOpenField, onChange) => {
  if (!isOpenField) {
    await onChange('openField', '');
    await onChange('isOpenField', false);
  } else await onChange('isOpenField', true);
  await onChange(name, value);
};

const openFieldChange = async (name, value, onChange) => {
  await onChange('isOpenField', value !== '');
  await onChange(name, value);
};

const renderOptions = (question, form, onChange) => {
  return question.options.map(option => {
    return [
      <RadioButton
        name={`answerId-${option.id}`}
        value="standard"
        labelText={option.description}
        checked={form.answerId.value === option.id}
        onChange={() =>
          radioChange('answerId', option.id, option.open_field, onChange)
        }
      />,
      option.open_field && form.answerId.value === option.id && (
        <TextInput
          placeholder=""
          maxLength={255}
          value={form.openField.value}
          labelText=""
          invalidText={form.openField.message}
          invalid={form.openField.message !== ''}
          onChange={event =>
            openFieldChange('openField', event.target.value, onChange)
          }
        />
      ),
    ];
  });
};

const renderQuestion = (detailAudience, form, onChange) => {
  return detailAudience.questions.map(question => {
    return (
      <FormGroup legendText={question.description} className="nr question">
        {renderOptions(question, form, onChange)}
      </FormGroup>
    );
  });
};

const renderForm = (detailAudience, form, onChange, next) => (
  <div className="bx--grid">
    <div className="bx--row">
      <div
        className="bx--col-sm-4 bx--offset-md-2
        bx--col-md-4 bx--offset-lg-5 bx--col-lg-6"
      >
        <Form>
          <p className="default">
            Para solicitar um novo pedido é necessário que você se identifique.
          </p>
          {renderQuestion(detailAudience, form, onChange)}
        </Form>
      </div>
    </div>
    <div className="bx--row">
      <div className="bx--col">
        <div className="bx--btn--container-center">
          <Button
            onClick={next}
            disabled={
              form.answerId.value === 0 ||
              (form.isOpenField.value && form.openField.value === '')
            }
          >
            Fazer Pedido
            {arrowRight()}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const Question = ({ detailAudience, form, onChange, next, isLoading }) =>
  isLoading ? <Loading /> : renderForm(detailAudience, form, onChange, next);

Question.propTypes = {
  form: PropTypes.shape({ answerId: PropTypes.number.isRequired }).isRequired,
  onChange: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  detailAudience: PropTypes.shape({
    questions: PropTypes.array,
  }),
};

Question.defaultProps = {
  detailAudience: {
    questions: [],
  },
};

export default Question;
