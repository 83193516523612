import React from 'react';
// import PropTypes from 'prop-types';

/* carbon - imports */
import {
  Checkbox,
  InlineLoading,
  InlineNotification,
  Button,
} from 'carbon-components-react';

/* core components - imports */
import Header from 'components/core/Layout/Header';
import Loading from 'components/core/Loading';
import Request from 'components/core/Request';

/* presentational components - imports */
import Success from 'components/presentational/AudienceVote/Success';

/* config - imports */
import * as urls from 'config/urls';

/* assets - imports */
import { arrowRight } from 'assets/icons';

/* styles - imports */
import './AudienceVoteStyle.scss';

const ListRequests = ({ orders, audienceId, votes, onChange }) => {
  return orders.map(order => (
    <div key={`boxVote-${order.id}`} className="bx--row">
      <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-2 bx--no-gutter--right av--box-vote">
        <Checkbox
          id={`vote${order.id}`}
          name={`vote${order.id}`}
          labelText=""
          checked={votes.includes(order.id)}
          onChange={value => {
            onChange(order.id, value);
          }}
        />
      </div>
      <div className="bx--col-sm-3 bx--col-md-15 bx--col-lg-14 bx--no-gutter--left">
        <Request key={order.id} order={order} audienceId={audienceId} />
      </div>
    </div>
  ));
};

const Content = ({
  orders,
  ordersYes,
  ordersNo,
  audienceId,
  votes,
  onChange,
  success,
  message,
  isLoading,
  onSubmit,
  index,
  isVoted,
}) => {
  return index === 0 && !isVoted ? (
    <div className="audience-vote">
      {audienceId}
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col">
            <h3 className="question">Vote nos pedidos de sua preferência.</h3>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col-sm-4 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">Sim</h4>
            <ListRequests
              key="listRequestsYes"
              orders={ordersYes}
              votes={votes}
              audienceId={audienceId}
              onChange={onChange}
            />
          </div>
          <div className="bx--col-sm-4 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">Não</h4>
            <ListRequests
              key="listRequestsNo"
              orders={ordersNo}
              votes={votes}
              audienceId={audienceId}
              onChange={onChange}
            />
          </div>
          <div className="bx--col-sm-4 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">Outros</h4>
            <ListRequests
              key="listRequests"
              orders={orders}
              votes={votes}
              audienceId={audienceId}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            {!success && message.length !== 0 && (
              <InlineNotification
                title="Ops, ocorreu algum erro!"
                subtitle={message.join('\n')}
                kind="error"
              />
            )}
            <div className="bx--btn--container-center">
              {isLoading || success ? (
                <InlineLoading description="Enviando..." success={success} />
              ) : (
                <Button
                  className="bx--btn--full-with av--send"
                  href="#"
                  onClick={() => onSubmit()}
                >
                  Votar
                  {arrowRight()}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Success audienceId={audienceId} />
  );
};

const AudienceVote = ({
  owner,
  title,
  orders,
  ordersYes,
  ordersNo,
  isLoading,
  audienceId,
  onModalSignIn,
  onSignOut,
  auth,
  votes,
  onChange,
  success,
  message,
  onSubmit,
  index,
  isVoted,
}) => [
  <Header
    title={title}
    subtitle={owner}
    url={urls.AUDIENCE(audienceId)}
    onSignOut={onSignOut}
    auth={auth}
    onModalSignIn={onModalSignIn}
  />,
  isLoading ? (
    <Loading />
  ) : (
    <Content
      orders={orders}
      ordersYes={ordersYes}
      ordersNo={ordersNo}
      audienceId={audienceId}
      votes={votes}
      onChange={onChange}
      success={success}
      message={message}
      isLoading={isLoading}
      onSubmit={onSubmit}
      index={index}
      isVoted={isVoted}
    />
  ),
];

export default AudienceVote;
