import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Link } from 'carbon-components-react';

/* core components - imports */
import User from 'components/core/Layout/User';

/* assets - imports */
import { arrowLeft } from 'assets/icons';

/* styles - imports */
import './HeaderInlineStyle.scss';

const HeaderInlineSubtitle = ({ subtitle }) =>
  subtitle !== '' && (
    <div className="hi-subtitle">
      <h3>{subtitle}</h3>
    </div>
  );

const HeaderInline = ({
  auth,
  onSignOut,
  onModalSignIn,
  title,
  subtitle,
  url,
  onClick,
}) => (
  <div className="header-inline-container" key="headerInlineComponent">
    <div className="hi--body">
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col-sm-3 bx--col-lg-15">
            <div className="hi--body-content">
              <Link
                className="hi--body-content-back"
                href={url}
                onClick={onClick}
              >
                {arrowLeft()}
              </Link>
              <h2 className="hi--body-content-title">{title}</h2>
            </div>
          </div>
          <div className="bx--col-sm-1 bx--col-lg-1">
            <User
              auth={auth}
              onSignOut={onSignOut}
              onModalSignIn={onModalSignIn}
            />
          </div>
        </div>
      </div>
    </div>
    <HeaderInlineSubtitle subtitle={subtitle} />
  </div>
);

HeaderInline.propTypes = {
  title: PropTypes.string.isRequired,
  auth: PropTypes.object,
  url: PropTypes.string,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
};

HeaderInline.defaultProps = {
  auth: null,
  onClick: () => {},
  subtitle: '',
  url: '#',
};

export default HeaderInline;
