import React from 'react';
// import PropTypes from 'prop-types';

/* core components - imports */
import Header from 'components/core/Layout/Header';
import Loading from 'components/core/Loading';
import Request from 'components/core/Request';

/* config - imports */
import * as urls from 'config/urls';

/* styles - imports */
import './AudienceResultStyle.scss';

const getVotes = votes => {
  if (votes > 0) {
    return `${votes} votos`;
  }
  return `0 voto`;
};

const ListRequests = ({ orders, audienceId, type }) => {
  return orders.map(order => (
    <div key={`boxVote-${order.id}`} className="bx--row">
      <div className="bx--col-sm-4 bx--col-md-16 bx--col-lg-16  bx--col-xlg-16">
        <div className="container-label">
          <span className={`label-status label-status--${type}`}>
            {getVotes(order.votes)}
          </span>
        </div>
        <Request key={order.id} order={order} audienceId={audienceId} />
      </div>
    </div>
  ));
};

const transformInPercentage = (totalOrders, totalVotes) => {
  return `${((totalVotes / totalOrders) * 100).toFixed(2)}%`;
};

const Content = ({
  orders,
  ordersYes,
  ordersNo,
  totalVotes,
  totalVotesYes,
  totalVotesNo,
  audienceId,
}) => {
  const votes = orders.map(order => order.votes);
  const tOrders =
    votes.length > 0
      ? votes.reduce((accumulator, value) => accumulator + value)
      : votes;

  const votesYes = ordersYes.map(order => order.votes);
  const tOrdersYes =
    votesYes.length > 0
      ? votesYes.reduce((accumulator, value) => accumulator + value)
      : votesYes;

  const votesNo = ordersNo.map(order => order.votes);
  const tOrdersNo =
    votesNo.length > 0
      ? votesNo.reduce((accumulator, value) => accumulator + value)
      : votesNo;
  const totalOrders = tOrders + tOrdersYes + tOrdersNo;

  const percentagem = transformInPercentage(totalOrders, totalVotes);
  const percentagemYes = transformInPercentage(totalOrders, totalVotesYes);
  const percentagemNo = transformInPercentage(totalOrders, totalVotesNo);

  return (
    <div className="audience-vote">
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col">
            <h3 className="question">Resultado final</h3>
          </div>
        </div>

        <div className="bx--row">
          <div className="bx--col-sm-3 bx--col-md-12">
            <div className="av--bar-progress">
              <div
                className="av--bar-progress-yes"
                style={{ width: percentagemYes }}
              />
              <div
                className="av--bar-progress-no"
                style={{ width: percentagemNo }}
              />
              <div
                className="av--bar-progress-others"
                style={{ width: percentagem }}
              />
            </div>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col-sm-1 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">
              Sim <span className="yes">{percentagemYes}</span>
            </h4>
            <ListRequests
              key="listRequestsYes"
              orders={ordersYes}
              audienceId={audienceId}
              type="yes"
            />
          </div>
          <div className="bx--col-sm-1 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">
              Não <span className="no">{percentagemNo}</span>
            </h4>
            <ListRequests
              key="listRequestsNo"
              orders={ordersNo}
              audienceId={audienceId}
              type="no"
            />
          </div>
          <div className="bx--col-sm-1 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">
              Outros <span className="others">{percentagem}</span>
            </h4>
            <ListRequests
              key="listRequests"
              orders={orders}
              audienceId={audienceId}
              type="others"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AudienceResult = ({
  owner,
  title,
  orders,
  ordersYes,
  ordersNo,
  totalVotes,
  totalVotesYes,
  totalVotesNo,
  isLoading,
  audienceId,
  onModalSignIn,
  onSignOut,
  auth,
  message,
}) => [
  <Header
    title={title}
    subtitle={owner}
    url={urls.AUDIENCE(audienceId)}
    onSignOut={onSignOut}
    auth={auth}
    onModalSignIn={onModalSignIn}
  />,
  isLoading ? (
    <Loading />
  ) : (
    <Content
      orders={orders}
      ordersYes={ordersYes}
      ordersNo={ordersNo}
      totalVotes={totalVotes}
      totalVotesYes={totalVotesYes}
      totalVotesNo={totalVotesNo}
      audienceId={audienceId}
      message={message}
      isLoading={isLoading}
    />
  ),
];

export default AudienceResult;
