import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as audiencesActions } from 'store/ducks/audiences';

/* presentational components - imports */
import Dashboard from 'components/presentational/Dashboard';

class DashboardContainer extends PureComponent {
  componentDidMount() {
    this.getAudiences(1);
  }

  async getAudiences(page) {
    const { audiencesList } = this.props;
    await audiencesList({ page });
  }

  render() {
    const { audiences, isLoading } = this.props;
    return (
      <Dashboard
        key="dashboardContainer"
        audiences={audiences}
        isLoading={isLoading}
      />
    );
  }
}

const mapStateToProps = state => ({
  audiences: state.audience.items,
  isLoading: state.audience.isLoading,
});

const mapDispatchToProps = dispatch => ({
  audiencesList: payload => dispatch(audiencesActions.audiencesList(payload)),
});

DashboardContainer.propTypes = {
  audiences: PropTypes.array,
  isLoading: PropTypes.bool,
  audiencesList: PropTypes.func.isRequired,
};

DashboardContainer.defaultProps = {
  audiences: [],
  isLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardContainer);
