import React from 'react';
import PropTypes from 'prop-types';

/* core components - imports */
import HeaderInline from 'components/core/Layout/HeaderInline';

/* presentational components - imports */
import Question from './Question';
import DataForm from './DataForm';
import AttachEvidence from './AttachEvidence';
import RequestProofs from './RequestProofs';
import RequestProofsTestimonial from './RequestProofsTestimonial';
import RequestProofsExpert from './RequestProofsExpert';
import RequestProofsDocumentary from './RequestProofsDocumentary';
import Success from './Success';

/* styles - imports */
import './NewRequestStyle.scss';

const renderForm = (
  index,
  form,
  onChange,
  onValidate,
  onSubmit,
  nextForm,
  setForm,
  addEvidence,
  removeEvidence,
  addRequestProof,
  removeRequestProof,
  detailAudience,
  isLoading,
  success,
  message,
  address,
  isLoadingAddress,
  onZipCode,
  audienceId,
) => {
  switch (index) {
    case 0:
      return (
        <Question
          key="questionForm"
          detailAudience={detailAudience}
          form={form}
          onChange={onChange}
          next={nextForm}
          isLoading={isLoading}
        />
      );
    case 1:
      return (
        <DataForm
          key="dataForm"
          form={form}
          onChange={onChange}
          onValidate={onValidate}
          onSubmit={onSubmit}
          setForm={setForm}
          isLoading={isLoading}
          success={success}
          message={message}
          removeEvidence={removeEvidence}
          removeRequestProof={removeRequestProof}
        />
      );
    case 2:
      return <AttachEvidence key="attachEvidence" addEvidence={addEvidence} />;
    case 3:
      return <RequestProofs key="requestProofs" setForm={setForm} />;
    case 4:
      return (
        <RequestProofsTestimonial
          key="requestProofsTestimonial"
          addRequestProof={addRequestProof}
          onZipCode={onZipCode}
          address={address}
          isLoadingAddress={isLoadingAddress}
        />
      );
    case 5:
      return (
        <RequestProofsExpert
          key="requestProofsTestimonial"
          addRequestProof={addRequestProof}
        />
      );
    case 6:
      return (
        <RequestProofsDocumentary
          key="requestProofsTestimonial"
          addRequestProof={addRequestProof}
        />
      );
    case 7:
      return <Success key="successForm" audienceId={audienceId} />;
    default:
      return (
        <Question
          key="questionForm"
          detailAudience={detailAudience}
          form={form}
          onChange={onChange}
          next={nextForm}
          isLoading={isLoading}
        />
      );
  }
};

const NewRequest = ({
  title,
  index,
  form,
  onChange,
  onValidate,
  onSubmit,
  nextForm,
  prevForm,
  setForm,
  addEvidence,
  removeEvidence,
  addRequestProof,
  removeRequestProof,
  detailAudience,
  isLoading,
  success,
  message,
  auth,
  onModalSignIn,
  onSignOut,
  address,
  isLoadingAddress,
  onZipCode,
  audienceId,
}) => [
  <HeaderInline
    key="newRequestHeaderInline"
    title={title[index]}
    onClick={prevForm}
    auth={auth}
    onModalSignIn={onModalSignIn}
    onSignOut={onSignOut}
  />,
  <div key="newRequestForm" className="new-request">
    {renderForm(
      index,
      form,
      onChange,
      onValidate,
      onSubmit,
      nextForm,
      setForm,
      addEvidence,
      removeEvidence,
      addRequestProof,
      removeRequestProof,
      detailAudience,
      isLoading,
      success,
      message,
      address,
      isLoadingAddress,
      onZipCode,
      audienceId,
    )}
  </div>,
];

NewRequest.propTypes = { history: PropTypes.object.isRequired };

export default NewRequest;
