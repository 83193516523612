import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import AuthReducer from './auth';
import AddressesReducer from './addresses';
import AudiencesReducer from './audiences';
import OrdersReducer from './orders';
import VotesReducer from './votes';

const appReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    addresses: AddressesReducer,
    audience: AudiencesReducer,
    orders: OrdersReducer,
    votes: VotesReducer,
  });

const Reducers = history => appReducer(history);

export default Reducers;
