import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Link } from 'carbon-components-react';

/* config - imports */
import * as urls from 'config/urls';

/* assets - imports */
import { comment, like } from 'assets/icons';

/* styles - imports */
import './RequestStyle.scss';

const renderDescription = description => {
  if (description.length > 200) return `${description.substr(0, 200)}(...)`;
  return description;
};

const Request = ({ audienceId, order }) => {
  return (
    <Link
      href={urls.AUDIENCE_REQUEST(audienceId, order.id, order.stageId)}
      className="request"
    >
      <div className="request-container">
        <h3 className="request-title">
          {renderDescription(order.description)}
        </h3>
        <div className="information">
          <div className="bx--row">
            <div className="bx--col">
              <p className="secondary icon-description inline">
                {order.comments}
                {comment()}
              </p>
              <p className="secondary icon-description inline">
                {order.likes}
                {like()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

Request.propTypes = {
  audienceId: PropTypes.string.isRequired,
  order: PropTypes.shape({
    description: PropTypes.string.isRequired,
    comments: PropTypes.number.isRequired,
    likes: PropTypes.number.isRequired,
  }),
};

export default Request;
