import React from 'react';

/* carbon - imports */
import {
  HeaderContainer,
  Header,
  SkipToContent,
  HeaderMenuButton,
  // HeaderMenu,
  HeaderName,
  // HeaderNavigation,
  // HeaderMenuItem,
  // HeaderGlobalAction,
  // HeaderGlobalBar,
  SideNav,
  SideNavItems,
  SideNavLink,
  // SideNavMenu,
  // SideNavMenuItem,
} from 'carbon-components-react';

/* styles - imports */
import './NavbarStyle.scss';

const Navbar = () => [
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }) => (
      <Header aria-label="PROCESSO COLETIVO ELETRÔNICO">
        <SkipToContent />
        <HeaderMenuButton
          aria-label="Open menu"
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
        />
        <HeaderName href="/" prefix="">
          PROCESSO COLETIVO ELETRÔNICO
        </HeaderName>
        {/* <HeaderGlobalBar>
          <HeaderGlobalAction
            aria-label="App Switcher"
            onClick={console.log('app-switcher click')}
          >
            <AppSwitcher20 />
          </HeaderGlobalAction>
        </HeaderGlobalBar> */}
        <SideNav aria-label="Side navigation" expanded={isSideNavExpanded}>
          <SideNavItems>
            {/* <SideNavMenu title="Category title">
              <SideNavMenuItem href="javascript:void(0)">Link</SideNavMenuItem>
              <SideNavMenuItem href="javascript:void(0)">Link</SideNavMenuItem>
            </SideNavMenu> */}
            <SideNavLink href="/audiencias">Audiências</SideNavLink>
            <SideNavLink href="/">Contato</SideNavLink>
            <SideNavLink href="/">Sobre</SideNavLink>
          </SideNavItems>
        </SideNav>
      </Header>
    )}
  />,
  <div className="navbar-fixed-margin" />,
];

export default Navbar;
