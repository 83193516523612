import { pdf, video, image, chat } from 'assets/icons';

const getIcon = type => {
  switch (type) {
    case 'pdf':
      return pdf;
    case 'image':
      return image;
    case 'video':
      return video;
    case 'chat':
      return chat;
    default:
      return pdf;
  }
};

export default getIcon;
