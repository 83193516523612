import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Button } from 'carbon-components-react';

/* core components - imports */
import AudienceInformation from 'components/core/AudienceInformation';

/* config - imports */
import * as urls from 'config/urls';

/* assets - imports */
import { arrowRight } from 'assets/icons';

/* styles - imports */
import './AudienceStyle.scss';

const renderDescription = description => {
  if (description.length > 500) return `${description.substr(0, 500)}(...)`;
  return description;
};

const Audience = ({
  id,
  title,
  owner,
  onlyDate,
  onlyHour,
  street,
  number,
  complement,
  city,
  state,
  zipCode,
  description,
}) => (
  <div className="bx--offset-lg-1 bx--col-lg-14">
    <div className="audience-container">
      <div className="audience-header">
        <div className="bx--row">
          <div className="bx--col-lg-8">
            <h2>{title}</h2>
          </div>
        </div>
      </div>
      <div className="audience-body">
        <div className="bx--row">
          <div className="bx--col-lg-8">
            <div className="owner">
              <h3>{owner}</h3>
            </div>
          </div>
        </div>
        <p className="primary description">{renderDescription(description)}</p>
        <AudienceInformation
          date={onlyDate}
          hour={onlyHour}
          // eslint-disable-next-line camelcase
          address={`${street}, ${number} ${complement}. ${city} ${state}, ${zipCode}`}
        />
        <Button href={urls.AUDIENCE(id)}>
          Saiba mais
          {arrowRight()}
        </Button>
      </div>
    </div>
  </div>
);

Audience.propTypes = {
  title: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  complement: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Audience;
