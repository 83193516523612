import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Link } from 'carbon-components-react';

/* assets - imports */
import { close } from 'assets/icons';

/* styles - imports */
import './BoxLinkStyle.scss';

const BoxLink = ({
  title,
  titleMore,
  more,
  description,
  onClick,
  removeItem,
  list,
}) =>
  list.length === 0 ? (
    <div className="box-link" role="presentation" onClick={() => onClick()}>
      <div className="bx--grid bl--container">
        <div className="bx--row">
          <div className="bx--col">
            <p className="bl--title">{title}</p>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <p className="bl--description">{description}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="box-link">
      <div className="bx--col-sm-4 bx--no-gutter">
        <p className="blf--title">{titleMore}</p>
      </div>
      {list.map((item, index) => {
        const { evidenceType, filename, url } = item;
        let { name } = item;
        if (item.typeProof) name = item.typeProof;
        return (
          <div key={`list${index}`} className="bx--row blf--container">
            <div className="bx--col-sm-3 bx--col-lg-15 blf--container-label bx--no-gutter">
              <p className="blf--labelText">
                {name}
                {evidenceType && (
                  <>
                    <br />
                    <span className="blf--labelSubText">
                      {evidenceType !== 'video' ? filename.name : url}
                    </span>
                  </>
                )}
              </p>
            </div>
            <div
              key={`list${index}`}
              className="bx--col-sm-1 bx--col-lg-1 bx--no-gutter blf--container-remove"
            >
              <Link href="#remove" onClick={() => removeItem(item)}>
                {close()}
              </Link>
            </div>
          </div>
        );
      })}
      <div className="bx--col-sm-4 blf--link">
        <Link href="#more" onClick={() => onClick()}>
          {more}
        </Link>
      </div>
    </div>
  );

BoxLink.propTypes = {
  title: PropTypes.string.isRequired,
  titleMore: PropTypes.string,
  more: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  description: PropTypes.string,
  list: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
  }).isRequired,
  listType: PropTypes.string.isRequired,
};

BoxLink.defaultProps = {
  titleMore: '',
  more: '',
  description: '',
};

export default BoxLink;
