import { takeLatest, put } from 'redux-saga/effects';
import ENDPOINTS from 'config/endpoints';
import axios from 'axios';

import { Types } from '../ducks/orders';

function getOrders(audienceId, votingClosed, favorable, page) {
  return axios
    .get(
      `${ENDPOINTS.GET_ORDERS_FROM_AUDIENCE(
        audienceId,
      )}?favorable=${favorable}&voting_closed=${votingClosed}&page=${page}&perPage=10`,
    )
    .then(res => res.data)
    .catch(error => {
      const response = error.response.status === 404 ? error.response : error;
      throw response;
    });
}

function getOrder(id) {
  return axios
    .get(`${ENDPOINTS.GET_ORDER(id)}`)
    .then(res => res.data)
    .catch(error => {
      const response = error.response.status === 404 ? error.response : error;
      throw response;
    });
}

function create(formData) {
  const accessToken = localStorage.getItem('accessToken');
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');

  return axios({
    method: 'POST',
    url: ENDPOINTS.CREATE_ORDER,
    headers: {
      'Content-Type': 'multipart/form-data',
      'access-token': accessToken,
      client,
      uid,
    },
    data: formData,
  })
    .then(res => res)
    .catch(e => {
      const response =
        e.response.status === 404 ? e.response : e.response.data.errors;
      throw response;
    });
}

function like(id) {
  const accessToken = localStorage.getItem('accessToken');
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');

  return axios({
    method: 'POST',
    url: ENDPOINTS.ORDER_LIKE(id),
    headers: {
      'access-token': accessToken,
      client,
      uid,
    },
  })
    .then(res => res)
    .catch(e => {
      const response =
        e.response.status === 404 ? e.response : e.response.data.errors;
      throw response;
    });
}

function comment(id, message) {
  const accessToken = localStorage.getItem('accessToken');
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');

  return axios({
    method: 'POST',
    url: ENDPOINTS.ORDER_COMMENT,
    headers: {
      'access-token': accessToken,
      client,
      uid,
    },
    data: {
      order_id: id,
      message,
    },
  })
    .then(res => res)
    .catch(e => {
      const response =
        e.response.status === 404 ? e.response : e.response.data.errors;
      throw response;
    });
}

function* ordersList({ payload }) {
  try {
    const response = yield getOrders(
      payload.audienceId,
      payload.votingClosed,
      payload.favorable,
      payload.page,
    );
    yield put({
      type: Types.ORDERS_LIST_SUCCESS,
      orders: response.orders,
      totalVotes: response.totalVotes,
    });
  } catch (e) {
    yield put({
      type: Types.ORDERS_LIST_FAIL,
      error: 'Erro ao listar as pedidos',
    });
  }
}

function* ordersYesList({ payload }) {
  try {
    const response = yield getOrders(
      payload.audienceId,
      payload.votingClosed,
      payload.favorable,
      payload.page,
    );
    yield put({
      type: Types.ORDERS_YES_LIST_SUCCESS,
      orders: response.orders,
      totalVotes: response.totalVotes,
    });
  } catch (e) {
    yield put({
      type: Types.ORDERS_YES_LIST_FAIL,
      error: 'Erro ao listar as pedidos',
    });
  }
}

function* ordersNoList({ payload }) {
  try {
    const response = yield getOrders(
      payload.audienceId,
      payload.votingClosed,
      payload.favorable,
      payload.page,
    );
    yield put({
      type: Types.ORDERS_NO_LIST_SUCCESS,
      orders: response.orders,
      totalVotes: response.totalVotes,
    });
  } catch (e) {
    yield put({
      type: Types.ORDERS_NO_LIST_FAIL,
      error: 'Erro ao listar as pedidos',
    });
  }
}

function* order({ payload }) {
  try {
    const response = yield getOrder(payload.id);
    yield put({ type: Types.ORDER_SUCCESS, order: response });
  } catch (e) {
    yield put({
      type: Types.ORDER_FAIL,
      error: 'Pedido não encontrado!',
    });
  }
}

function* createOrder({ payload, finish }) {
  try {
    const response = yield create(payload);
    finish();
    yield put({
      type: Types.CREATE_ORDER_SUCCESS,
      order: response.data.data,
    });
  } catch (messages) {
    yield put({
      type: Types.CREATE_ORDER_FAIL,
      message: messages,
    });
  }
}

function* setLike({ payload }) {
  try {
    const response = yield like(payload);
    yield put({ type: Types.ORDER_LIKE_SUCCESS, order: response });
  } catch (messages) {
    yield put({
      type: Types.ORDER_LIKE_FAIL,
      error: messages,
    });
  }
}

function* setComment({ payload }) {
  try {
    const response = yield comment(payload.id, payload.comment);
    yield put({ type: Types.ORDER_COMMENT_SUCCESS, order: response.data });
  } catch (messages) {
    yield put({
      type: Types.ORDER_COMMENT_FAIL,
      error: messages,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.CREATE_ORDER, createOrder);
  yield takeLatest(Types.ORDERS_LIST, ordersList);
  yield takeLatest(Types.ORDERS_YES_LIST, ordersYesList);
  yield takeLatest(Types.ORDERS_NO_LIST, ordersNoList);
  yield takeLatest(Types.ORDER, order);
  yield takeLatest(Types.ORDER_LIKE, setLike);
  yield takeLatest(Types.ORDER_COMMENT, setComment);
}
