import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as authActions } from 'store/ducks/auth';
import { Creators as audiencesActions } from 'store/ducks/audiences';
import { Creators as ordersActions } from 'store/ducks/orders';
import { Creators as votesActions } from 'store/ducks/votes';

/* modal components - imports */
import SignInContainer from 'containers/SignInContainer';
import SignUpContainer from 'containers/SignUpContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';

/* presentational components - imports */
import AudienceVote from 'components/presentational/AudienceVote';

/* config - imports */
import { history } from 'config/routes';

class AudienceVoteContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.onModalRequest = this.onModalRequest.bind(this);
    this.onModalSignIn = this.onModalSignIn.bind(this);
    this.onModalSignUp = this.onModalSignUp.bind(this);
    this.onModalForgotPassword = this.onModalForgotPassword.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      index: 0,
      votes: [],
      modalRequest: false,
      modalSignIn: false,
      modalSignUp: false,
      modalForgotPassword: false,
      onCallback: () => {},
    };
  }

  componentDidMount() {
    const {
      ordersList,
      ordersYesList,
      ordersNoList,
      match: {
        params: { audienceId },
      },
    } = this.props;

    const votingClosed = false;

    this.getAudience(audienceId);
    ordersList({ audienceId, votingClosed, favorable: 'other', page: 1 });
    ordersYesList({ audienceId, votingClosed, favorable: 'yes', page: 1 });
    ordersNoList({ audienceId, votingClosed, favorable: 'no', page: 1 });
  }

  async getAudience(id) {
    const { audience } = this.props;
    await audience({ id });
  }

  async onModalSignIn(onCallback = () => {}) {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignUp) await this.onModalSignUp();
    this.setState({
      modalSignIn: !modalSignIn,
      modalForgotPassword: false,
      onCallback,
    });
  }

  async onModalSignUp() {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignIn) await this.onModalSignIn();
    this.setState({ modalSignUp: !modalSignUp, modalForgotPassword: false });
  }

  async onModalForgotPassword(onCallback = () => {}) {
    const { modalForgotPassword } = this.state;
    this.setState({
      modalForgotPassword: !modalForgotPassword,
      modalSignIn: false,
      onCallback,
    });
  }

  async onModalRequest() {
    const { auth } = this.props;
    if (auth) {
      this.setState({ modalRequest: !this.state.modalRequest });
    } else {
      this.onModalSignIn(() =>
        this.setState({ modalRequest: !this.state.modalRequest }),
      );
    }
  }

  async onSignOut() {
    const { signOut } = this.props;
    await signOut();
  }

  onChange(name, value) {
    const { votes } = this.state;
    const orderIds = votes;

    if (value) {
      if (!orderIds.includes(name)) {
        this.setState({ votes: [...orderIds, name] });
      }
    } else {
      const index = orderIds.indexOf(name);

      if (index > -1) {
        orderIds.splice(index, 1);
        this.setState({ orderIds }, this.forceUpdate());
      }
    }
  }

  onSubmit() {
    const { votes } = this.state;
    const {
      createVote,
      match: {
        params: { audienceId, stageId },
      },
    } = this.props;

    const data = {
      audience_id: audienceId,
      stage_id: stageId,
      order_ids: votes.filter(vote => vote),
    };

    createVote(data, () => this.setState({ index: 1 }));
  }

  render() {
    const {
      modalRequest,
      modalSignIn,
      modalSignUp,
      modalForgotPassword,
      onCallback,
      votes,
      index,
    } = this.state;
    const {
      orders,
      ordersYes,
      ordersNo,
      detailAudience,
      isLoading,
      auth,
      success,
      message,
      match: {
        params: { audienceId },
      },
    } = this.props;

    return [
      <AudienceVote
        onModalRequest={this.onModalRequest}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onSignOut={this.onSignOut}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        modalRequest={modalRequest}
        index={index}
        history={history}
        audienceId={audienceId}
        orders={orders}
        ordersYes={ordersYes}
        ordersNo={ordersNo}
        isLoading={isLoading}
        auth={auth}
        votes={votes}
        message={message}
        success={success}
        {...detailAudience}
      />,
      <SignInContainer
        modalSignIn={modalSignIn}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
      <SignUpContainer
        modalSignUp={modalSignUp}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
      />,
      <ForgotPasswordContainer
        modalForgotPassword={modalForgotPassword}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  detailAudience: state.audience.audience,
  orders: state.orders.orders,
  ordersYes: state.orders.ordersYes,
  ordersNo: state.orders.ordersNo,
  success: state.auth.success,
  message: state.auth.message,
  isLoading:
    state.orders.isLoading ||
    state.orders.isLoadingNo ||
    state.orders.isLoadingYes ||
    state.audience.isLoading,
});

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(authActions.signOut()),
  audience: payload => dispatch(audiencesActions.audience(payload)),
  ordersList: payload => dispatch(ordersActions.ordersList(payload)),
  ordersYesList: payload => dispatch(ordersActions.ordersYesList(payload)),
  ordersNoList: payload => dispatch(ordersActions.ordersNoList(payload)),
  createVote: (payload, finish) =>
    dispatch(votesActions.createVote(payload, finish)),
});

AudienceVoteContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  ordersList: PropTypes.func.isRequired,
  ordersYesList: PropTypes.func.isRequired,
  ordersNoList: PropTypes.func.isRequired,
  orders: PropTypes.array,
  isLoading: PropTypes.bool,
};

AudienceVoteContainer.defaultProps = {
  orders: [],
  isLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceVoteContainer);
