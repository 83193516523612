import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  createVote: ['payload', 'finish'],
  createVoteSuccess: [],
  createVoteFail: ['message'],
});

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  message: [],
};

const requestCreateVote = () => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const requestCreateVoteSuccess = () => ({
  ...INITIAL_STATE,
  success: true,
});
const requestCreateVoteFail = (state, action) => ({
  ...INITIAL_STATE,
  message: action.message,
});

export default createReducer(INITIAL_STATE, {
  [Types.CREATE_VOTE]: requestCreateVote,
  [Types.CREATE_VOTE_SUCCESS]: requestCreateVoteSuccess,
  [Types.CREATE_VOTE_FAIL]: requestCreateVoteFail,
});
