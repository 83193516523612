import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Modal } from 'carbon-components-react';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as authActions } from 'store/ducks/auth';

/* presentational components - imports */
import ForgotPassword from 'components/presentational/ForgotPassword';

/* utils - imports */
import INPUTS from 'utils/inputs';

class ForgotPasswordContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.authForgotPassword = this.authForgotPassword.bind(this);
    this.onCallback = this.onCallback.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email: {
        value: '',
        message: '',
        validations: ['required', 'email'],
      },
    };
  }

  async authForgotPassword(payload) {
    const { forgotPassword } = this.props;
    await forgotPassword({ ...payload, callback: this.onCallback });
  }

  async onCallback() {
    const { onModalSignIn } = this.props;
    await onModalSignIn();
  }

  onChange(name, value) {
    if (this.state[name].value !== value)
      this.setState(INPUTS.onChange(name, this.state[name], value));
  }

  onValidate(name, value) {
    this.setState({ [name]: INPUTS.onValidate(this.state[name], value) });
  }

  async onSubmit() {
    let { email } = this.state;

    email = INPUTS.onValidate(email, email.value);

    if (email.message === '') {
      await this.authForgotPassword({ email: email.value });
    } else {
      this.setState({
        ...this.state,
        email,
      });
    }
  }

  render() {
    const { modalSignUp } = this.state;
    const {
      isLoading,
      success,
      message,
      modalForgotPassword,
      onModalForgotPassword,
      onModalSignUp,
    } = this.props;

    return (
      <Modal
        modalHeading="Esqueci minha senha"
        open={modalForgotPassword}
        primaryButtonText="Continuar"
        secondaryButtonText="Cancelar"
        onRequestClose={onModalForgotPassword}
        onRequestSubmit={this.onSubmit}
        onSecondarySubmit={onModalForgotPassword}
      >
        <ForgotPassword
          key="ForgotPasswordContainer"
          onChange={this.onChange}
          onValidate={this.onValidate}
          onSubmit={this.onSubmit}
          modalSignUp={modalSignUp}
          onModalSignUp={onModalSignUp}
          isLoading={isLoading}
          success={success}
          message={message}
          {...this.state}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  success: state.auth.success,
  message: state.auth.message,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: payload => dispatch(authActions.forgotPassword(payload)),
});

ForgotPasswordContainer.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  onModalForgotPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCallback: PropTypes.func,
};

ForgotPasswordContainer.defaultProps = {
  onCallback: () => {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordContainer);
