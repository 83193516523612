import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Link } from 'carbon-components-react';

/* styles - imports */
import './AudienceRecordStyle.scss';

const AudienceRecordDescription = description =>
  description !== '' ? <p className="ar--description">{description}</p> : null;

const AudienceRecordContent = ({ icon, title, primary, description }) => (
  <div className="bx--grid ar--container">
    <div className="bx--row">
      <div className={`bx--col-sm-4`}>
        <p className={`ar--title ${primary ? 'ar--title-primary' : ''}`}>
          {icon()}
          {title}
        </p>
      </div>
    </div>
    <div className="bx--row">
      <div className="bx--col">{AudienceRecordDescription(description)}</div>
    </div>
  </div>
);

const AudienceRecord = ({
  icon,
  title,
  primary,
  description,
  url,
  stealthy,
}) => (
  <div
    className={`audience-record ${
      description !== '' ? 'ar--spacing-05' : 'ar--spacing-03'
    } ${stealthy ? 'stealthy' : ''}`}
  >
    {stealthy ? (
      <AudienceRecordContent
        icon={icon}
        title={title}
        primary={primary}
        description={description}
      />
    ) : (
      <Link href={url} target="_blank">
        <AudienceRecordContent
          icon={icon}
          title={title}
          primary={primary}
          description={description}
        />
      </Link>
    )}
  </div>
);

AudienceRecord.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  description: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
};

AudienceRecord.defaultProps = {
  description: '',
  primary: false,
  type: 'audience',
  url: null,
};

export default AudienceRecord;
