import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Button } from 'carbon-components-react';

/* core components - imports */
import Request from 'components/core/Request';

/* assets - imports */
import { plus } from 'assets/icons';

/* styles - imports */
import './RequestsProcessedStyle.scss';

const ListRequests = ({ orders, audienceId }) =>
  orders.map(order => (
    <Request key={order.id} order={order} audienceId={audienceId} />
  ));

const RequestsProcessed = ({
  onModalRequest,
  orders,
  ordersYes,
  ordersNo,
  audienceId,
  hiddenRequests,
}) => {
  return (
    <div className="requests-processed-container">
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col-sm-1 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">Sim</h4>
            <ListRequests orders={ordersYes} audienceId={audienceId} />
            {!hiddenRequests && (
              <Button
                className="bx--btn bx--btn--gray bx--btn--sm bx--btn--request"
                onClick={onModalRequest}
              >
                Fazer pedido
                {plus()}
              </Button>
            )}
          </div>
          <div className="bx--col-sm-1 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">Não</h4>
            <ListRequests orders={ordersNo} audienceId={audienceId} />
            {!hiddenRequests && (
              <Button
                className="bx--btn bx--btn--gray bx--btn--sm bx--btn--request"
                onClick={onModalRequest}
              >
                Fazer pedido
                {plus()}
              </Button>
            )}
          </div>
          <div className="bx--col-sm-1 bx--col-md-4 bx--col-lg-5 bx--col-xlg-4">
            <h4 className="order-type">Outros</h4>
            <ListRequests orders={orders} audienceId={audienceId} />
            {!hiddenRequests && (
              <Button
                className="bx--btn bx--btn--gray bx--btn--sm bx--btn--request"
                onClick={onModalRequest}
              >
                Fazer pedido
                {plus()}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

RequestsProcessed.propTypes = {
  onModalRequest: PropTypes.func.isRequired,
  hiddenRequests: PropTypes.bool,
};

RequestsProcessed.defaultProps = {
  hiddenRequests: false,
};

export default RequestsProcessed;
