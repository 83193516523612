import React from 'react';
import PropTypes from 'prop-types';

/* assets - imports */
import { calendar, clock, pin } from 'assets/icons';

/* styles - imports */
import './AudienceInformationStyle.scss';

const AudienceInformation = ({ date, hour, address }) => (
  <div className="audience-information">
    <p className="secondary ai--title">AUDIÊNCIA PÚBLICA</p>
    <div className="bx--row">
      <div className="bx--col">
        <p className="secondary ai--icon-description inline">
          {calendar()}
          {date}
        </p>
        <p className="secondary ai--icon-description inline">
          {clock()}
          {hour}
        </p>
      </div>
    </div>
    {address !== null && address !== '' && (
      <div className="bx--row">
        <div className="bx--col">
          <p className="secondary ai--icon-description">
            {pin()}
            {address}
          </p>
        </div>
      </div>
    )}
  </div>
);

AudienceInformation.propTypes = {
  date: PropTypes.string.isRequired,
  hour: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default AudienceInformation;
