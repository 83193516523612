const CONSTANTS = {
  // IMAGE_URL: 'http://api.digiz.com.br',
  // URL_HOST: 'http://api.digiz.com.br/api',

  IMAGE_URL: 'https://api.processocoletivo.com',
  URL_HOST: 'https://api.processocoletivo.com/api',

  // IMAGE_URL: 'http://localhost:3000',
  // URL_HOST: 'http://localhost:3000/api',
};

export default CONSTANTS;
