import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  signIn: ['payload', 'location'],
  signInSuccess: ['user'],
  signInFail: ['message'],

  signUp: ['payload'],
  signUpSuccess: ['user'],
  signUpFail: ['message'],

  signOut: [],
  signOutSuccess: [],
  signOutFail: ['message'],

  forgotPassword: ['payload'],
  forgotPasswordSuccess: [],
  forgotPasswordFail: ['message'],
});

const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem('user')),
  isLoading: false,
  success: false,
  message: [],
};

const requestSignIn = () => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const requestSignInSuccess = (state, action) => ({
  ...INITIAL_STATE,
  success: true,
  user: action.user,
});
const requestSignInFail = (state, action) => ({
  ...INITIAL_STATE,
  message: action.message,
});

const requestSignUp = () => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const requestSignUpSuccess = (state, action) => ({
  ...INITIAL_STATE,
  success: true,
  user: action.user,
});
const requestSignUpFail = (state, action) => ({
  ...INITIAL_STATE,
  message: action.message,
});

const requestSignOut = () => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const requestSignOutSuccess = () => ({
  ...INITIAL_STATE,
  user: JSON.parse(localStorage.getItem('user')),
});
const requestSignOutFail = (state, action) => ({
  ...INITIAL_STATE,
  message: action.message,
});

const requestForgotPassword = () => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const requestForgotPasswordSuccess = () => ({
  ...INITIAL_STATE,
});
const requestForgotPasswordFail = (state, action) => ({
  ...INITIAL_STATE,
  message: action.message,
});

export default createReducer(INITIAL_STATE, {
  [Types.SIGN_IN]: requestSignIn,
  [Types.SIGN_IN_SUCCESS]: requestSignInSuccess,
  [Types.SIGN_IN_FAIL]: requestSignInFail,

  [Types.SIGN_UP]: requestSignUp,
  [Types.SIGN_UP_SUCCESS]: requestSignUpSuccess,
  [Types.SIGN_UP_FAIL]: requestSignUpFail,

  [Types.SIGN_OUT]: requestSignOut,
  [Types.SIGN_OUT_SUCCESS]: requestSignOutSuccess,
  [Types.SIGN_OUT_FAIL]: requestSignOutFail,

  [Types.FORGOT_PASSWORD]: requestForgotPassword,
  [Types.FORGOT_PASSWORD_SUCCESS]: requestForgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAIL]: requestForgotPasswordFail,
});
