import React from 'react';
import PropTypes from 'prop-types';

/* styles - imports */
import './CommentStyle.scss';

const Comment = ({ name, description }) => (
  <div className="comment">
    <div className="bx--grid c--container">
      <div className="bx--row">
        <div className="bx--col">
          <p className="c--name">{name}</p>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <p className="c--description">{description}</p>
        </div>
      </div>
    </div>
  </div>
);

Comment.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Comment;
