import React from 'react';
import PropTypes from 'prop-types';

/* styles - imports */
import './BoxTypeEvidenceStyle.scss';

const BoxTypeEvidence = ({ title, description, onClick }) => (
  <div className="box-type-evidence" onClick={() => onClick()}>
    <div className="bx--grid bte--container">
      <div className="bx--row">
        <div className="bx--col">
          <p className="bte--title">{title}</p>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <p className="bte--description">{description}</p>
        </div>
      </div>
    </div>
  </div>
);

BoxTypeEvidence.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BoxTypeEvidence;
