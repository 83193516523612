import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as authActions } from 'store/ducks/auth';
import { Creators as audiencesActions } from 'store/ducks/audiences';

/* modal components - imports */
import SignInContainer from 'containers/SignInContainer';
import SignUpContainer from 'containers/SignUpContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';

/* presentational components - imports */
import DetailAudience from 'components/presentational/DetailAudience';

/* config - imports */
import { history } from 'config/routes';

class DetailAudienceContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.onModalSignIn = this.onModalSignIn.bind(this);
    this.onModalSignUp = this.onModalSignUp.bind(this);
    this.onModalForgotPassword = this.onModalForgotPassword.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
    this.state = {
      modalSignIn: false,
      modalSignUp: false,
      modalForgotPassword: false,
      onCallback: () => {},
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { audienceId },
      },
    } = this.props;
    this.getAudience(audienceId);
  }

  async getAudience(id) {
    const { audience } = this.props;
    await audience({ id });
  }

  async onModalSignIn(onCallback = () => {}) {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignUp) await this.onModalSignUp();
    this.setState({
      modalSignIn: !modalSignIn,
      modalForgotPassword: false,
      onCallback,
    });
  }

  async onModalSignUp() {
    const { modalSignIn, modalSignUp } = this.state;
    if (modalSignIn) await this.onModalSignIn();
    this.setState({ modalSignUp: !modalSignUp, modalForgotPassword: false });
  }

  async onModalForgotPassword(onCallback = () => {}) {
    const { modalForgotPassword } = this.state;
    this.setState({
      modalForgotPassword: !modalForgotPassword,
      modalSignIn: false,
      onCallback,
    });
  }

  async onSignOut() {
    const { signOut } = this.props;
    await signOut();
  }

  render() {
    const {
      modalSignIn,
      modalSignUp,
      modalForgotPassword,
      onCallback,
    } = this.state;
    const {
      auth,
      detailAudience,
      isLoading,
      error,
      match: {
        params: { audienceId },
      },
    } = this.props;

    return [
      <DetailAudience
        key="detailAudienceContainer"
        isLoading={isLoading}
        history={history}
        {...detailAudience}
        audienceId={audienceId}
        auth={auth}
        onSignOut={this.onSignOut}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        error={error}
      />,
      <SignInContainer
        modalSignIn={modalSignIn}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
      <SignUpContainer
        modalSignUp={modalSignUp}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
      />,
      <ForgotPasswordContainer
        modalForgotPassword={modalForgotPassword}
        onCallback={onCallback}
        onModalSignIn={this.onModalSignIn}
        onModalSignUp={this.onModalSignUp}
        onModalForgotPassword={this.onModalForgotPassword}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  detailAudience: state.audience.audience,
  error: state.audience.error,
  isLoading: state.audience.isLoading,
});

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(authActions.signOut()),
  audience: payload => dispatch(audiencesActions.audience(payload)),
});

DetailAudienceContainer.propTypes = {
  audience: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  detailAudience: PropTypes.object,
  isLoading: PropTypes.bool,
};

DetailAudienceContainer.defaultProps = {
  detailAudience: null,
  isLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailAudienceContainer);
