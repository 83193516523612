import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import { Modal } from 'carbon-components-react';

/* redux - imports */
import { connect } from 'react-redux';
import { Creators as authActions } from 'store/ducks/auth';

/* presentational components - imports */
import SignIn from 'components/presentational/SignIn';

/* utils - imports */
import INPUTS from 'utils/inputs';

class SignInContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.authSignIn = this.authSignIn.bind(this);
    this.onCallback = this.onCallback.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email: {
        value: '',
        message: '',
        validations: ['required', 'email'],
      },
      password: {
        value: '',
        message: '',
        validations: ['required', 'password'],
      },
    };
  }

  async authSignIn(payload) {
    const { signIn } = this.props;
    await signIn({ ...payload, callback: this.onCallback });
  }

  async onCallback() {
    const { onModalSignIn, onCallback } = this.props;
    onModalSignIn();
    await onCallback();
  }

  onChange(name, value) {
    if (this.state[name].value !== value)
      this.setState(INPUTS.onChange(name, this.state[name], value));
  }

  onValidate(name, value) {
    this.setState({ [name]: INPUTS.onValidate(this.state[name], value) });
  }

  async onSubmit() {
    let { email, password } = this.state;

    email = INPUTS.onValidate(email, email.value);
    password = INPUTS.onValidate(password, password.value);

    if (email.message === '' && password.message === '') {
      await this.authSignIn({
        email: email.value,
        password: password.value,
      });
    } else {
      this.setState({
        ...this.state,
        email,
        password,
      });
    }
  }

  render() {
    const { modalSignUp } = this.state;
    const {
      isLoading,
      success,
      message,
      modalSignIn,
      onModalSignIn,
      onModalSignUp,
      onModalForgotPassword,
    } = this.props;

    return (
      <Modal
        modalHeading="Fazer login"
        open={modalSignIn}
        primaryButtonText="Continuar"
        secondaryButtonText="Cancelar"
        onRequestClose={onModalSignIn}
        onRequestSubmit={this.onSubmit}
        onSecondarySubmit={onModalSignIn}
      >
        <SignIn
          key="signInContainer"
          onChange={this.onChange}
          onValidate={this.onValidate}
          onSubmit={this.onSubmit}
          modalSignUp={modalSignUp}
          onModalSignUp={onModalSignUp}
          onModalForgotPassword={onModalForgotPassword}
          isLoading={isLoading}
          success={success}
          message={message}
          {...this.state}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  success: state.auth.success,
  message: state.auth.message,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  signIn: payload => dispatch(authActions.signIn(payload)),
});

SignInContainer.propTypes = {
  signIn: PropTypes.func.isRequired,
  onModalSignIn: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    allow_password_change: PropTypes.string.isRequired,
    document: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    complement: PropTypes.string.isRequired,
    neighborhood: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }),
  onCallback: PropTypes.func,
};

SignInContainer.defaultProps = {
  onCallback: () => {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInContainer);
